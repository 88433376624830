/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import { getHomePageInfo } from '../../../../app/modules/apps/user-management/customers-list/core/_requests'

type Props = {
  className: string
}

const ListsWidget6: React.FC<Props> = ({className}) => {
  const [visiters, setVisiters] = useState<any>()
  const getVisiters = async () => {
    await getHomePageInfo().then(response => {
      setVisiters(response.data.siparis_veren)
    })
  }
  useEffect(() => {
    getVisiters()
  }, [])
  useEffect(() => {
  }, [visiters])
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Ziyaretçiler</h3>
       
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        {/* begin::Item */}
        {/* begin::Row */}
        <div className='row g-0'>
            {/* begin::Col */}
            <div className='col'>
              {/* begin::Label */}
              <div className='fs-7 text-muted fw-semibold'>Günlük Ziyaretçi </div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className='d-flex align-items-center'>
                <div className='fs-4 fw-bold'>250</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col'>
              {/* begin::Label */}
              <div className='fs-7 text-muted fw-semibold'>Sipariş Veren</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className='fs-4 fw-bold'>{visiters}</div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row g-0 mt-8'>
            {/* begin::Col */}
            <div className='col mr-8'>
              {/* begin::Label */}
              <div className='fs-7 text-muted fw-semibold'>Toplam Zaman Geçirme Süresi</div>
              {/* end::Label */}

              {/* begin::Stat */}
              <div className='fs-4 fw-bold'>16000</div>
              {/* end::Stat */}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget6}
