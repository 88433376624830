import { KTCard } from "../../../../../_metronic/helpers"

import { BlogTable } from "../table/BlogTable"
import { BlogEditModal } from "../user-edit-modal/BlogEditModal"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { BlogListHeader } from "./header/BlogListHeader"


const OffersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <BlogListHeader />
        <BlogTable/>
      </KTCard>
      {itemIdForUpdate !== undefined && <BlogEditModal />}
    </>
  )
}

const BlogListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OffersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BlogListWrapper}
