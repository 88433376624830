import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Setting, initialUser } from '../settings-list/core/_models';
import { useListView } from '../settings-list/core/ListViewProvider';
import { useQueryResponse } from '../settings-list/core/QueryResponseProvider';
import { addPopUp, editPopUp, updatePopUp } from '../settings-list/core/_requests';
import { SettingsListLoading } from '../loading/SettingsListLoading';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ]
}

type Props = {
  isUserLoading: boolean
  user: Setting
}

const editUserSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Başlık zorunludur'),
  dosya_video: Yup.string().required('Yüklemek istediğiniz belgenin türünü seçmek zorunludur'),
})
const SettingsEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const [value, setValue] = useState('');
  const [isCompany, setIsCompany] = useState<any>()
  const videoSchema = Yup.object().shape({
    video: Yup.string()
      .matches(
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/,
        'Geçerli bir YouTube linki giriniz'
      )
      .required('Video linki girmek zorunludur'),
  })
  const textSchema = Yup.object().shape({
    text: Yup.string().required("Metin yüklemek zorunludur.")
  })
  const fileSchema = Yup.object().shape({
    image: Yup.string().required("Dosya yüklemek zorunludur.")
  })
  const registrationSchemaSon = useMemo(() => {
    if (isCompany === "1") {
      return editUserSchema.concat(videoSchema)
    } else if (isCompany === "2") {
      return editUserSchema.concat(textSchema);
    } else {
      return editUserSchema.concat(fileSchema)
    }
  }, [isCompany])
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [image, setImage] = useState<unknown>("");
  const [userForEdit] = useState<Setting>({
    ...user,
    title: user.title || initialUser.title,
    active: user.active || initialUser.active,
    text: user.text || initialUser.text,
    image: user.image || initialUser.image,
    video: user.video || initialUser.video,
    dosya_video: user.dosya_video || initialUser.dosya_video,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')


  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: registrationSchemaSon,
    onSubmit: async (values, { setSubmitting }) => {
      values = { ...values, image: String(image), active: formik.values.active }
      setSubmitting(true)
      try {
        if (user.id !== undefined) {
          await updatePopUp(values).then((response) => {

          })
        } else {
          await addPopUp(values).then((response) => {

          })
        }

      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  const handleCreateBase64 = useCallback(async (e: any) => {
    const file = e.target.files[0];
    const name = e.target.name
    const base64 = await convertToBase64(file);
    formik.setFieldValue("image", `${base64}`)
    setImage(base64);
    e.target.value = "";
  }, [])
  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) {
        alert('select an image')
      }
      else {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  const deleteImage = (e: any) => {
    e.preventDefault();
    setImage(null);
  }
  useEffect(() => {
    if (formik.values.dosya_video !== "1") {
      formik.setFieldValue("video", '')
    }
    formik.setFieldValue("image", '')
    formik.setFieldValue("text", '')
  }, [formik.values.dosya_video])
  useEffect(() => {
    setIsCompany(formik.values.dosya_video)
  }, [formik.values.dosya_video])
  useEffect(() => { formik.setFieldValue("text", value) }, [value])
  useEffect(() => {
    if (user?.text) {
      formik.setFieldValue("dosya_video", "2")
      setValue(user?.text);
    } else if (user?.video) {
      formik.setFieldValue("dosya_video", "1")
      formik.setFieldValue("video", user?.video)
    } else if (user?.image) {
      formik.setFieldValue("dosya_video", "0")
      const image = toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}popup/` + user?.image)
      setImage(image)
    }
  }, [user])
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* end::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            <input
              placeholder='Başlık Giriniz'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.title && formik.errors.title },
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div>
          <div className='row mb-0'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 mb-2'>Aktif</label>
            <div className='col-lg-8 d-flex align-items-center'>
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  id='allowmarketing'
                  defaultChecked={formik.values.active}
                  {...formik.getFieldProps('active')}
                />
              </div>
            </div>
          </div>
          <div className='fv-row mb-7 mt-3'>
            <select id="inputState" className={clsx(
              'form-select form-select-solid form-select-lg fw-bold',
              { 'is-invalid': formik.touched.dosya_video && formik.errors.dosya_video },
              {
                'is-valid': formik.touched.dosya_video && !formik.errors.dosya_video,
              }
            )
            } {...formik.getFieldProps('dosya_video')}>
              <option value={""} hidden>Tür seç</option>
              <option value={0}>Dosya</option>
              <option value={1}>Video</option>
              <option value={2}>Metin</option>
            </select>
            {formik.touched.dosya_video && formik.errors.dosya_video && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dosya_video}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='fv-row mb-7'>
          {formik.values.dosya_video === "0" ?
            <>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='d-block fs-6 mb-5 required fw-bold '>Resim</label>
                {/* end::Label */}

                {/* begin::Image input */}
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{ backgroundImage: `url('${blankImg}')` }}
                >
                  {/* begin::Preview existing avatar */}
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{ backgroundImage: `url('${image}')` }}
                  >
                    {/* {logos ? (<img src="logos" alt="" />) : null} */}
                  </div>
                  {/* end::Preview existing avatar */}

                  {/* begin::Label */}
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>

                    <input
                      type='file'
                      {...formik.getFieldProps('image')}
                      value={undefined}
                      onChange={handleCreateBase64}
                      accept='.png, .jpg, .jpeg, .webp, .svg'
                    />
                    <input type='hidden' onClick={deleteImage} name='avatar_remove' />
                  </label>
                  {/* end::Label */}

                  {/* begin::Cancel */}
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                  {/* end::Cancel */}

                  {/* begin::Remove */}
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove avatar'
                    onClick={deleteImage}
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                  {/* end::Remove */}
                </div>
                {/* end::Image input */}

                {/* begin::Hint */}
                <div className='form-text'>İzin verilen formatlar: png, jpg, jpeg.</div>
                {/* end::Hint */}
                {formik.touched.image && formik.errors.image && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.image}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
            :
            <>
              {formik.values.dosya_video === "1" ?
                <>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Video Url</label>
                    <input
                      placeholder='Video Linki Giriniz'
                      {...formik.getFieldProps('video')}
                      type='text'
                      name='video'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        { 'is-invalid': formik.touched.video && formik.errors.video },
                        {
                          'is-valid': formik.touched.video && !formik.errors.video,
                        }
                      )}
                      autoComplete='off'
                    />
                    {formik.touched.video && formik.errors.video && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.video}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </>
                :
                <>
                  {formik.values.dosya_video === "2" ?
                    <>
                      <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Metin</label>
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          modules={modules}
                          className={clsx(
                            "form-control ",
                            {
                              'is-invalid': formik.touched.text && formik.errors.text,
                            },
                            {
                              'is-valid': formik.touched.text && !formik.errors.text
                            }
                          )}
                          placeholder="Metin Giriniz"
                        />
                        {formik.touched.text && formik.errors.text && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.text}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                    :
                    <>
                    </>
                  }
                </>
              }
            </>
          }
        </div>
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Kaydet</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Lütfen Bekleyin...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <SettingsListLoading />}
    </>
  )
}

export { SettingsEditModalForm }
