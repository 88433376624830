import React from 'react'
import { OffersListWrapper } from '../user-management/offer-list/OffersList'
import { BlogListWrapper } from '../blog/blog-list/BlogListWrapper'
import { ProjectListWrapper } from './project-list/ProjectListWrapper'
export const Proje = () => {
  return (
    <div>
      <h1>Projee</h1>
      <ProjectListWrapper/>
    </div>
  )
}
