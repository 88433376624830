import React, { useEffect, useRef, useState } from 'react'
import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'


export const ProjectListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [header, setHeader] = useState<string | undefined>("")
  const [subtitle, setSubtitle] = useState<string | undefined>("")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const datePickerRef = useRef<any>(null);
  const handleDateChange = (date: any) => {
    if (!startDate && !endDate) {
      setStartDate(date);
    } else if (startDate && !endDate) {
      setEndDate(date);
    }
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null)
    }
  }
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const orderByDate = (date: any) => {
    const formatDate = new Date(date);
    let formatMonth = String(formatDate.getMonth() + 1)
    if (Number(formatMonth) < 10) {
      formatMonth = "0" + formatMonth
    }
    return formatDate.getFullYear() + "-" + formatMonth + "-" + formatDate.getDate() + " " + String(formatDate.getHours()) + "0" + ":" + String(formatDate.getMinutes()) + "0" + ":" + String(formatDate.getSeconds()) + "0";;
  }

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
      setTimeout(() => {
        datePickerRef.current.setOpen(true);
      });
    }
    setStartDate(null);
    setEndDate(null);
    setHeader("")
  }

  const filterData = () => {
    let start = ""
    let end = ""
    if (startDate && endDate) {
      start = orderByDate(startDate)
      end = orderByDate(endDate)
    } else if (startDate && !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Hatalı...',
        text: 'Bitiş Tarihi seçmek zorunludur',
      })
    }
    updateState({
      filter: { header: header, subtitle: subtitle, startDate:start, endDate:end },
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filtrele
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filtreleme Opsiyonları</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Başlık</label>
            <input
              placeholder='Başlık giriniz'
              className='form-control form-control-solid mb-3 mb-lg-0'
              type='text'
              name='fullname'
              autoComplete='off'
              onChange={(e) => { setHeader(e.target.value) }}
              value={header}
            />
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Alt Başlık</label>
            <input
              placeholder='Alt Başlık giriniz'
              className='form-control form-control-solid mb-3 mb-lg-0'
              type='text'
              name='fullname'
              autoComplete='off'
              onChange={(e) => { setSubtitle(e.target.value) }}
              value={subtitle}
            />
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Oluşturulma Tarihi</label>
            <DatePicker
              ref={datePickerRef}
              onChange={(date) => handleDateChange(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              selectsEnd
              inline
            />
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Sıfırla
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Onayla
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}
