import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { Offer, UsersQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const GET_OFFERS_URL = `${API_URL}offerTransactions/offerList`
const GET_OFFERS_DETAIL_URL = `${API_URL}offerTransactions/offerDetail`
const GET_CURRENCY_LIST_URL = `${API_URL}GuestController/getCurrencyList`
const GIVE_OFFER_URL = `${API_URL}offerTransactions/give_offer`
const ADD_OFFER_DOCUMENTS = `${API_URL}offerTransactions/addOfferDocuments`
const GET_LOGS = `${API_URL}logTransactions/getLogs`
const GET_MESSAGES = `${API_URL}chatTransactions/getMessages`
const ADD_MESSAGES = `${API_URL}chatTransactions/addMessage`
const ADD_OFFER_PROGRESS = `${API_URL}offerTransactions/offerProgress`
const ADD_OFFER_SUBSCRIPTION = `${API_URL}offerTransactions/offerSubscription`
const CANCEL_OFFER = `${API_URL}offerTransactions/cancelOffer`
const ADD_BILL_URL = `${API_URL}offerTransactions/addOfferInvoice`
const DOWNLOAD_BILL_URL = `${API_URL}offerTransactions/offerInvoiceDownload`
const DELETE_FILES_URL =`${API_URL}offerTransactions/offerDeleteFiles`
const GET_LANGUAGE_LIST_URL = `${API_URL}GuestController/listLang`
const LIST_EXPERTISES_URL = `${API_URL}GuestController/listExptertises`
const LIST_MAİN_SERVİCES_URL = `${API_URL}GuestController/listMainServices`
const LIST_ORDER_CLASSES_URL= `${API_URL}GuestController/listOrderClass`

const downloadBill = (data:any) => {
  return axios.post(`${DOWNLOAD_BILL_URL}`,data)
}

const addBill = (data:any) => {
  return axios.post(`${ADD_BILL_URL}`,data)
}

const addOfferProgress = (data:any) => {
  return axios.post(`${ADD_OFFER_PROGRESS}`,data)
}

const addOfferSubscription = (data:any) => {
  return axios.post(`${ADD_OFFER_SUBSCRIPTION}`,data)
}

const cancelOffer = (data:any) => {
  return axios.post(`${CANCEL_OFFER}`,data)
}

const getOffers = (query: string, user_id: number = 0): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_OFFERS_URL}/${user_id}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getOfferById = (id: ID): Promise<Offer | undefined> => {
  const all = axios
    .get(`${GET_OFFERS_DETAIL_URL}/${id}`)
    .then((response: Response<Offer>) => response.data)
  return all;
}

const addOfferDocument = (data: any): Promise<any | undefined> => {
  const all = axios
    .post(`${ADD_OFFER_DOCUMENTS}`, data)
    .then((response: Response<any>) => response.data)
  return all;
}

const deleteOfferDocument = (userId: ID): Promise<void> => {
  return axios.delete(`${DELETE_FILES_URL}/${userId}`).then(() => { })
}
const createUser = (user: Offer): Promise<Offer | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<Offer>>) => response.data)
    .then((response: Response<Offer>) => response.data)
}

const updateUser = (user: Offer): Promise<Offer | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: Response<Offer>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const getCurrencies = () => {
  return axios.get(GET_CURRENCY_LIST_URL)
}

const giveOffer = (values: any) => {
  const all = axios.post(GIVE_OFFER_URL, values)
  return all
}

const getLogs = (offer_id: any) => {
  return axios.get(`${GET_LOGS}/${offer_id}`)
}

const getMessages = (offer_id : any) => {
  return axios.get(`${GET_MESSAGES}/${offer_id}`)
}

const addMessage = (data:any ) => {
  const all = axios.post(`${ADD_MESSAGES}`,data)
  return all
} 

const getLanguage = () => {
  return axios.get(GET_LANGUAGE_LIST_URL)
}

const getExpertises = () => {
  return axios.get(LIST_EXPERTISES_URL)
}

const getMainServices = () => {
  return axios.get(LIST_MAİN_SERVİCES_URL)
}



const getOrderClasses = () => {
  return axios.get(LIST_ORDER_CLASSES_URL)
}



export { cancelOffer, deleteOfferDocument, getOrderClasses, getMainServices, getExpertises, getLanguage, downloadBill, addBill, addOfferSubscription, addOfferProgress, addMessage , getMessages , getOffers, deleteUser, deleteSelectedUsers, getOfferById, createUser, updateUser, getCurrencies, giveOffer, addOfferDocument , getLogs }
