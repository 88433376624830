import { KTCard } from "../../../../../_metronic/helpers"

import { ProjectTable } from "../table/ProjectTable"
import { ProjectEditModal } from "../user-edit-modal/ProjectEditModal"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { ProjectListHeader } from "./header/ProjectListHeader"


const OffersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ProjectListHeader />
        <ProjectTable/>
      </KTCard>
      {itemIdForUpdate !== undefined && <ProjectEditModal />}
    </>
  )
}

const ProjectListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OffersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProjectListWrapper}
