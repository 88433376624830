import React from 'react'
import { ReferenceListFilter } from './ReferenceListFilter'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'

export const ReferenceListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
       <ReferenceListFilter />  {/* Filter işlemi için kullanılıyor. */}

       {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
       Referans Ekle
      </button>
      {/* end::Add user */}
    </div>
  )
}
