import React from 'react'
import { useListView } from '../core/ListViewProvider'
import { SettingsListSearchComponent } from './SettingsListSearchComponent'
import { SettingsListGrouping } from './SettingsListGrouping'
import { SettingsListToolbar } from './SettingsListToolbar'

export const SettingsListHeader = () => {
    const { selected } = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            <SettingsListSearchComponent />
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {selected.length > 0 ? <SettingsListGrouping/> : <SettingsListToolbar />}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}
