import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useParams } from 'react-router-dom';
import { getUserByToken, login } from '../../auth/core/_requests';
import { useAuth } from '../../auth';

const TokenTransaction: FC = () => {
  const { saveAuth, setCurrentUser, setTokenPage } = useAuth()
  const { token, page } = useParams();
  console.log("Page", page)
  const [tokenNav, setTokenNav] = useState("");
  const tokenAuth = async () => {
    //const { data: auth } = await login(String(token), String(page))
    if (token !== "") {

      const { data: user } = await getUserByToken(String(token))
      console.log("data user", user)
      if (user.islem === "basarili") {
        console.log("basirili")
        
        if (page) {
          let navPage = String(page).replaceAll("&","/")
          console.log("navPage",navPage)
          setTokenPage("/"+navPage)
        } else {
          setTokenPage("auth")
        }
      } else {
        console.log("basirisiz")
        setTokenPage("error/404")
      }
      saveAuth({ islem: "basarili", api_token: String(token) })
      setCurrentUser(user.veri)
    } else {
      console.error("Hata")
      saveAuth(undefined)
    }
  }
  useEffect(() => {
    tokenAuth()
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/18.png')}
          alt=''
          className='mw-100 mb-10 h-lg-450px'
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
          Yönlendiriliyorsunuz
        </h1>
        {/* end::Message */}
        {/* begin::Link */}
        <Link to='/' className='btn btn-primary'>
          Return Home
        </Link>
        {/* end::Link */}
      </div>
    </div>
  );
}

export { TokenTransaction }
