import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Setting, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_REFERENCE_DETAIL_URL = `${API_URL}contentTransactions/reference/Detail`
const EDİT_REFERENCE_URL = `${API_URL}contentTransactions/reference/Edit`
const ADD_POP_UP_URL = `${API_URL}contentTransactions/popup/Add`
const GET_POP_UPS_URL = `${API_URL}contentTransactions/popup/List`
const DELETE_POP_UP_URL = `${API_URL}contentTransactions/popup/Delete`
const GET_POP_UP_DETAİL_URL = `${API_URL}GuestController/contentTransactions/popup/Detail`
const EDIT_POP_UP = `${API_URL}contentTransactions/popup/Edit`
const UPDATE_POP_UP = `${API_URL}contentTransactions/popup/Update`

const editPopUp = (data:any) => {
  return axios
    .post(`${EDIT_POP_UP}`, data)
    .then((response: any) => response.data)

}

const updatePopUp = (data:any) => {
  return axios
    .post(`${UPDATE_POP_UP}`, data)
    .then((response: any) => response.data)

}

const addPopUp = (user: Setting) => {
  const all = axios
  .post(ADD_POP_UP_URL, user)
  .then((response: Response<Setting>) => response.data)
  return all
}


const getPopUps = (query: string): Promise<UsersQueryResponse> => {
  console.log("query",query)
  return axios
    .get(`${GET_POP_UPS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const deletePopUp = (userId: ID): Promise<void> => {
  return axios.delete(`${DELETE_POP_UP_URL}/${userId}`).then(() => {})
}


const getPopUpDetails = (id: ID): Promise<Setting | undefined> => {
  const all = axios
    .post(`${GET_POP_UP_DETAİL_URL}/${id}`)
    .then((response: Response<Setting>) => response.data)
    
    return all;
}

const editReference = (user: Setting): Promise<Setting | undefined> => {
  return axios
    .post(`${EDİT_REFERENCE_URL}/${user.id}`, user)
    .then((response: Response<Setting>) => response.data)
}


const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${DELETE_POP_UP_URL}/${id}`))
  return axios.all(requests).then(() => {})
}



export {updatePopUp, editPopUp,deletePopUp, getPopUps, deleteSelectedUsers, getPopUpDetails, addPopUp, editReference}
