import React, { FC, useEffect, useState } from 'react'
import { Setting } from '../settings-list/core/_models'
import { toAbsoluteUrl } from './../../../../../_metronic/helpers/AssetHelpers';
type Props = {
    isUserLoading: boolean
    user: Setting
}

export const PopUpLook: FC<Props> = ({ user, isUserLoading }) => {
    const [data, setData] = useState<any>()
    const [video, setVideo] = useState<any>("")
    useEffect(() => {
        setData(user)
    }, [])
    useEffect(() => {
        if (data?.video !== "") {
            const videoId = data?.video.match(/watch\?v=(.*)/)[1]
            setVideo(videoId)
        }
    }, [data])
    return (
        <div>
            {data?.text !== "" ?
                <div
                    dangerouslySetInnerHTML={{ __html: data?.text }}
                />
                :
                <>
                    {data?.image !== "" ?
                        <>
                            <div className="w-100 h-100 mb-2 justify-content-center  text-align-center data?-flex">
                                <img src={toAbsoluteUrl(`${process.env.REACT_APP_MEDIA_API_URL}popup/` + data?.image)} className="img-fluid w-50 rounded" alt="Popup Image" />
                            </div>
                        </>
                        :
                        <>
                            {data?.video !== "" ?
                                <>
                                    <div className="w-100 h-100 mb-2  justify-content-center  text-align-center data?-flex">
                                        <div className='video-wrapper '><iframe
                                            className='rounded'
                                            width="600"
                                            height="315"
                                            src={`https://www.youtube.com/embed/${video}`}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
                                        </iframe>
                                        </div>
                                    </div>
                                </>
                                :
                                null}
                        </>
                    }
                </>
            }
        </div>
    )
}
