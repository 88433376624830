/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../helpers'
import { addMessage, getMessages } from '../../../app/modules/apps/user-management/offer-list/core/_requests'
import { useAuth } from '../../../app/modules/auth'

type Props = {
  isDrawer?: boolean
}

const bufferMessages = defaultMessages

const ChatInner = ({ isDrawer = true, id }: any) => {
  var btnBottom = document.querySelector("#kt_scroll_change_pos_bottom");
  const { currentUser } = useAuth()
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any>()
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const offer_id = Number(id)
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const GetMessages = () => {
    if (offer_id) {
      getMessages(offer_id ?? 0).then((response) => {
        setMessages(response.data)
      })
    }
  }

  const sendMessage = async () => {
    const chatobj = { message, offer_id }
    await addMessage(chatobj).then((response) => {
      const newMessage: MessageModel = {
        id: 0,
        name: '',
        surname: '',
        message: message,
        message_id: 0,
        cr_date: 'Şimdi',
        icon: String(currentUser?.icon),
        offer_id: offer_id,
        sender: Number(currentUser?.control_id),
        type: 'out'
      }
      setMessages((prevState: any) => [...prevState, newMessage])
      toggleChatUpdateFlat(!chatUpdateFlag)
      setMessage('')
      scrollChatToBottom();
    })
  }
  const scrollChatToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  useEffect(() => {
    GetMessages()
  }, [])

  return (
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}

    >
      <div
        ref={chatContainerRef}
        className={clsx('scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >

        {messages && messages?.length > 0 ? (messages?.map((message: any, index: any) => {
          if (currentUser?.control_id === message.sender) {
            const state = 'info'
            const templateAttr = {}
            if (message.template) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-${message.type}`,
              })
            }
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.type === 'in' ? 'start' : 'end'
              } mb-10`
            return (
              <div
                key={`message${index}`}
                className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                {...templateAttr}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    <div className='me-3'>
                      <span className='text-muted fs-7 mb-1'>{message.cr_date}</span>
                      <a
                        href='#'
                        className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                      >
                        Sen
                      </a>
                    </div>
                    <div className='symbol  symbol-35px symbol-circle '>
                      <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/${message.icon}`)} />
                    </div>
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px',
                      `text-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{ __html: message.message }}
                  ></div>
                </div>
              </div>
            )
          } else {
            const state = 'primary'
            const templateAttr = {}
            if (message.template) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-${message.type}`,
              })
            }
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-start mb-10`
            return (
              <div
                key={`message${index}`}
                className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                {...templateAttr}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-start`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    <div className='symbol  symbol-35px symbol-circle '>
                      <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/${message.icon}`)} />
                    </div>
                    <div className='ms-3'>
                      <a
                        href='#'
                        className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                      >
                        {message.name}
                      </a>
                      <span className='text-muted fs-7 mb-1'>{message.cr_date}</span>
                    </div>
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px',
                      `text-start`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{ __html: message.message }}
                  ></div>
                </div>
              </div>
            )
          }

        })) :
          <></>
        }
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Mesaj Yaz'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-end'>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Gönder
          </button>
        </div>
      </div>
    </div>
  )
}

export { ChatInner }
