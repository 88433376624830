import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { changePassword } from '../core/_requests'
import ReactCodeInput from 'react-code-input'
import './custom.css'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const initialValues = {
    changepassword: "",
    password: "",
    token: "",
}

const changePasswordSchema = Yup.object().shape({
    token: Yup.string()
        .required('Doğrulama Kodu zorunludur')
        .min(6, 'Bütün kutucukları doldurun')
        .max(6, 'Maksimum 6 karakter'),
    password: Yup.string()
        .min(3, 'Minimum 3 karakter')
        .max(50, 'Maksimum 50 karakter')
        .required('Parola zorunludur'),
    changepassword: Yup.string()
        .required('Parolanızı doğrulamanız zorunludur')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Parola ve doğrulanmış parola uyuşmuyor"),
        }),
})

export const ChangePassword = () => {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                changePassword(values.changepassword, values.password, values.token)
                    .then(({ data: { result } }) => {
                        if (result.islem === "basarili") {
                            setHasErrors(false)
                            setLoading(false)
                        } else if (result.islem === "hatali") {
                            setHasErrors(true)
                            setLoading(false)
                            setSubmitting(false)
                            setStatus(result.hata)
                        } else {
                            setHasErrors(true)
                            setLoading(false)
                            setSubmitting(false)
                            setStatus('Sunucuya bağlanılamadı')
                        }
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Giriş bilgileri yanlış')
                    })
            }, 1000)
        },
    })

    const mgz = (e: any) => {
        formik.setFieldValue("token", e)
        formik.setTouched({ ...formik.touched, ["token"]: true });
    }
    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_change_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='d-flex flex-column flex-center py-0 px-5 px-md-15 w-100'>
                {/* begin::Logo */}
                <Link to='/' className=''>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/PNG.png')} style={{ height: "200px" }} />
                </Link>
            </div>
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Şifreni değiştir</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Doğrulama Kodunu gir ve şifreni sıfırla
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {formik.status}
                    </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>Parola sıfırlama isteğini gönderdik. Lütfen e-postanızı kontrol edin</div>
                </div>
            )}
            {/* end::Title */}

            {/* begin: Form group Token*/}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Doğrulama Kodunu Giriniz</label>
                <ReactCodeInput
                    inputMode={'numeric'} type='number'
                    fields={6}
                    className='react-input-code'
                    name={formik.getFieldProps('token').name}
                    value={formik.getFieldProps('token').value}
                    onChange={(e: any) => (mgz(e))}
                />
                {formik.touched.token && formik.errors.token && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.token}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end: Form group Token*/}

            {/* begin::Form group Password */}
            <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Parola</label>
                    <div className='position-relative mb-3'>
                        <input
                            type='password'
                            placeholder='Parola'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control bg-transparent',
                                {
                                    'is-invalid': formik.touched.password && formik.errors.password,
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* begin::Meter */}
                    <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                    >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                    {/* end::Meter */}
                </div>
                <div className='text-muted'>
                    8 veya daha fazla Harf, sayı ve karakterden oluşan parola kullanın.
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Parolanızı doğrulayınız</label>
                <input
                    type='password'
                    placeholder='Parolanızı doğrulayınız'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                        },
                        {
                            'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                        }
                    )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='kt_password_change_submit' className='btn btn-primary me-4'>
                    <span className='indicator-label'>Gönder</span>
                    {loading && (
                        <span className='indicator-progress'>
                            Lütfen bekleyin...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_change_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        İptal
                    </button>
                </Link>{''}
            </div>
            {/* end::Form group */}
        </form>
    )
}
