import { KTCard } from "../../../../../_metronic/helpers"

import { ReferenceTable } from "../table/ReferenceTable"
import { ReferenceEditModal } from "../user-edit-modal/ReferenceEditModal"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { ReferenceListHeader } from "./header/ReferenceListHeader"


const OffersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ReferenceListHeader />
        <ReferenceTable/>
      </KTCard>
      {itemIdForUpdate !== undefined && <ReferenceEditModal />}
    </>
  )
}

const ReferenceListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OffersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ReferenceListWrapper}
