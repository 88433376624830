// import React from 'react'
// import {MenuItem} from './MenuItem'
// import {useIntl} from 'react-intl'
import { DefaultTitle } from './page-title/DefaultTitle'

export function MenuInner() {
  // const intl = useIntl()
  return (
    <>
      <DefaultTitle/>
    </>
  )
}
