import { FC, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { TagsInput } from "react-tag-input-component";
import clsx from 'clsx'
import { Blog, initialUser } from '../blog-list/core/_models'
import { useListView } from '../blog-list/core/ListViewProvider'
import { useQueryResponse } from '../blog-list/core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { addBlog, editBlog } from '../blog-list/core/_requests'
import { BlogListLoading } from '../loading/BlogListLoading'
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ link: 'link' }],
  ]
}
type Props = {
  isUserLoading: boolean
  user: Blog
}
const editUserSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Başlık zorunludur'),
  subtitle: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Alt Başlık zorunludur'),
})
const BlogEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [value, setValue] = useState('');
  const [valueSummary, setValueSummary] = useState('');
  const [content, setContent] = useState('');
  const [logo, setLogo] = useState<unknown>("" || undefined);
  const [contentlogo, setContentLogo] = useState<unknown>("" || undefined);
  const [selected, setSelected] = useState(["içerik"]);
  const [userForEdit] = useState<Blog>({
    ...user,
    title: user.title || initialUser.title,
    subtitle: user.subtitle || initialUser.subtitle,
    list_text: user.list_text || initialUser.list_text,
    summary_text: user.summary_text || initialUser.summary_text,
    content: user.content || initialUser.content,
    image: user.image || initialUser.image,
    content_image: user.content_image || initialUser.content_image,
    tags: user.tags || initialUser.tags,


  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values = { ...values, image: String(logo), content_image: String(contentlogo) }
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await editBlog(values).then((response) => {
          })
        } else {
          await addBlog(values).then((response) => {
          })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  const handleCreateBase64 = useCallback(async (e: any) => {
    const file = e.target.files[0];
    const name = e.target.name
    const base64 = await convertToBase64(file);

    if (name === "image") {
      formik.values.image = `${base64}`
      setLogo(base64);
    } else {
      formik.values.content_image = `${base64}`
      setContentLogo(base64)
    }

    e.target.value = "";
  }, [])
  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) {
        alert('select an image')
      }
      else {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  useEffect(() => {
    if (user.id !== undefined) {
      setValue(String(user.list_text || initialUser.list_text));
      setValueSummary(String(user.summary_text || initialUser.summary_text));
      setContent(String(user.content || initialUser.content));
    } else {
      console.log("girmeedi", user)
    }

  }, [user])

  useEffect(() => {
    setLogo(`${process.env.REACT_APP_MEDIA_API_URL}blogs/` + user?.image)
  }, [])

  useEffect(() => {
    setContentLogo(`${process.env.REACT_APP_MEDIA_API_URL}blogs/` + user?.content_image)
  }, [])
  const deleteImage = (e: any) => {
    e.preventDefault();
    setLogo(null);
  }
  const deleteContentImage = (e: any) => {
    e.preventDefault();
    setContentLogo(null);
  }
  useEffect(() => { formik.setFieldValue("list_text", value) }, [value])
  useEffect(() => { formik.setFieldValue("summary_text", valueSummary) }, [valueSummary])
  useEffect(() => { formik.setFieldValue("content", content) }, [content])
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Başlık Giriniz'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.title && formik.errors.title },
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Alt Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Alt Başlık giriniz'
              {...formik.getFieldProps('subtitle')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.subtitle && formik.errors.subtitle },
                {
                  'is-valid': formik.touched.subtitle && !formik.errors.subtitle,
                }
              )}
              type='text'
              name='subtitle'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.subtitle && formik.errors.subtitle && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.subtitle}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fs-6 mb-5 required fw-bold '>Resim</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${logo}')` }}
              >
                {/* {logo ? (<img src="logo" alt="" />) : null} */}
              </div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input

                  type='file'
                  {...formik.getFieldProps('image')}
                  value={undefined}
                  onChange={handleCreateBase64}
                  accept='.png, .jpg, .jpeg, .webp, .svg'


                />
                <input type='hidden' onClick={deleteImage} name='avatar_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={deleteImage}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'>İzin verilen formatlar: png, jpg, jpeg.</div>
            {/* end::Hint */}
            {formik.touched.image && formik.errors.image && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.image}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fs-6 mb-5 required fw-bold '>İçerik Resmi</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${contentlogo}')` }}
              >
                {/* {logo ? (<img src="logo" alt="" />) : null} */}
              </div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input

                  type='file'
                  {...formik.getFieldProps('content_image')}
                  value={undefined}
                  onChange={handleCreateBase64}
                  accept='.png, .jpg, .jpeg, .webp, .svg'


                />
                <input type='hidden' onClick={deleteContentImage} name='avatar_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={deleteContentImage}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'>İzin verilen formatlar: png, jpg, jpeg.</div>
            {/* end::Hint */}
            {formik.touched.content_image && formik.errors.content_image && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content_image}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Liste Yazısı</label>
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.list_text && formik.errors.list_text,
                },
                {
                  'is-valid': formik.touched.list_text && !formik.errors.list_text
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.list_text && formik.errors.list_text && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.list_text}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Özet Yazı</label>
            <ReactQuill
              theme="snow"
              value={valueSummary}
              onChange={setValueSummary}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.summary_text && formik.errors.summary_text,
                },
                {
                  'is-valid': formik.touched.summary_text && !formik.errors.summary_text
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.summary_text && formik.errors.summary_text && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.summary_text}</span>
                </div>
              </div>
            )}
          </div>


          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>İçerik</label>
            <ReactQuill
              theme="snow"
              value={content}
              onChange={setContent}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.content && formik.errors.content,
                },
                {
                  'is-valid': formik.touched.content && !formik.errors.content
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Tag</label>
            {/* end::Label */}
            <TagsInput
              {...formik.getFieldProps('tags')}

              value={formik.values.tags}
              onChange={tags => formik.setFieldValue('tags', tags)}
              // onChange={setSelected}
              // name="tags"
              placeHolder="Tag ekle"
            />
            <em>press enter or comma to add new tag</em>
            {/* begin::Input */}
            <span
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.tags && formik.errors.tags },
                {
                  'is-valid': formik.touched.tags && !formik.errors.tags,
                }
              )}
            />
            {/* end::Input */}
            {formik.touched.tags && formik.errors.tags && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.tags}</span>
              </div>
            )}
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{isNotEmpty(formik.values.id) ? 'Güncelle' : 'Kaydet'}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Lütfen Bekleyin...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <BlogListLoading />}
    </>
  )
}

export { BlogEditModalForm }
