import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import { KTCardBody } from '../../../../../_metronic/helpers'
import { ReferenceHeaderColumn } from '../columns/ReferenceHeaderColumn'
import { CustomRow } from '../columns/CustomRow'
import { ReferenceListPagination } from '../pagination/ReferenceListPagination'
import { ReferenceListLoading } from '../loading/ReferenceListLoading'
import { usersColumns } from '../columns/_columns'
import { useQueryResponseData, useQueryResponseLoading } from '../reference-list/core/QueryResponseProvider'
import { Reference } from '../reference-list/core/_models'
import { Referans } from './../Referans';


const ReferenceTable = () => {
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Reference>) => (
                <ReferenceHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Reference>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Referans bulunamadı
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ReferenceListPagination />
      {isLoading && <ReferenceListLoading />}
    </KTCardBody>
  )
}

export {ReferenceTable}
