import React from 'react'
import { ReferenceListSearchComponent } from './ReferenceListSearchComponent'
import { ReferenceListGrouping } from './ReferenceListGrouping'
import { ReferenceListToolbar } from './ReferenceListToolbar'
import { useListView } from '../core/ListViewProvider'

export const ReferenceListHeader = () => {
    const { selected } = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            <ReferenceListSearchComponent />
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {selected.length > 0 ? <ReferenceListGrouping /> : <ReferenceListToolbar />}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}
