import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const LİST_CITY_URL = `${API_URL}GuestController/listCity`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/sendResetPassword`
export const CHANGE_PASSWORD_URL = `${API_URL}/changePasswordWithToken`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
 values:object
) {

  let allvalues = {
    ...values,
  };
  const result = axios.post(REGISTER_URL, allvalues)
  return result
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  const result =axios.post<{result: any}>(REQUEST_PASSWORD_URL, {
    email,
  }) 
  return result
}

export function changePassword(email:string, token: string, passwords: string){
  const result =axios.post<{result: any}>(CHANGE_PASSWORD_URL, {
    email,token,passwords
  }) 
  return result
}

export function getUserByToken(token: string) {
  const result = axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
  return result
}

export function GetCities(country_id=223) {
  return axios.get(LİST_CITY_URL+'/'+country_id)
}
