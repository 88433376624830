import React, { useEffect } from 'react'

export const KvkkModal = () => {

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Kişisel Verilerin Korunması Kanunu Kapsamındaki Bilgilendirme Metni</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>FURKAN KURT (LOCALINATION) ŞİRKETİ KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ <br />  <br />
                                İşbu Aydınlatma Metni, FURKAN KURT (LOCALINATION) Şirketi (“”Şirket”) tarafından Şirket’in müşterilerinin 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) kapsamında kişisel verilerinin Şirket tarafından işlenmesine ilişkin olarak aydınlatılması amacıyla hazırlanmıştır. <br />
                                localination aktarılan kişisel verilerin korunması konusundaki temel bilgilere aşağıda yer vermiştir. <br />
                                localination, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) md. 10’dan doğan aydınlatma yükümlülüğünü yerine getirmek amacıyla aşağıdaki açıklamaları; müşterilerimizin ve web-sitemizi kullanan, KVKK’nın md.3’deki tanım gereği sadece gerçek kişi olan 3. kişilerin dikkatine sunar. (localination, işbu Kişisel Verilerin Korunması Hakkında Açıklama metnini yürürlükteki mevzuatta yapılabilecek değişiklikler çerçevesinde her zaman güncelleme hakkını saklı tutar.) <br />
                                6698 sayılı Kanun uyarınca, veri sorumlusu sıfatını haiz localination tarafından elde edilmiş/edilecek ya da paylaştığınız/paylaşacağınız şahsınıza ait her türlü bilginin (“kişisel veri”), aşağıda açıklanan çerçevede ve anılan Kanunda öngörülen şekillerde localination tarafından işleneceğini ve bilgilerinizin otomatik olan ya da olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, açıklanması, aktarılması, elde edilebilir hale getirilmesi de dâhil olmak üzere bilgileriniz üzerinde gerçekleştirilen her türlü işlemin “kişisel verilerin işlenmesi” anlamına geldiğini bilgilerinize sunarız. Ayrıca 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun da kişisel verilerin korunmasına ilişkin hüküm içermektedir. 5237 Sayılı Türk Ceza Kanunu hükümleri yoluyla da kişisel verilerin korunması için bazı hallerde cezai yaptırımlar öngörülmüştür. Diğer yandan, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği’nden doğan yükümlülüklerimizin ifası amacıyla verilerin toplanması ve kullanılması gerekmektedir. <br /> <br />

                                Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri <br />
                                localination, başta 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hk. Kanun, 4358 sayılı Vergi Kimlik Numarası Kullanımının Yaygınlaştırılması Hk. Kanun olmak üzere ilgili tüm ulusal/uluslararası mevzuat ve bunlara dayalı olarak ulusal/uluslararası yetkili otoritelerce yayımlanmış olan ikincil düzenlemeler ya da bunlarla ilgili olarak veya faaliyetleri ile ilgili olarak taraf olduğu sözleşmeler kapsamındaki yükümlülüklerinin (kimlik tespiti, bilgi saklama, raporlama, bilgilendirme vb.) ve taahhütlerinin yerine getirilmesi; localination’den talep ettiğiniz her türlü hizmetlere ilişkin sözleşmelerin kurulması ve diğer tüm kayıt ve belgelerin (kağıt veya elektronik ortamda) düzenlenmesi, söz konusu hizmetlerin sunulması ve de gereği gibi kesintisiz olarak sürdürülebilmesi gibi amaçlar ve hukuki sebeplerle kişisel verileriniz localination  tarafından işlenmektedir. <br /> <br />

                                localination, kişisel verileri hangi amaçlarla kullanıyor? <br />
                                localination, mevzuatın izin verdiği durumlarda ve ölçüde kişisel bilgilerinizi kaydedebilecek, saklayabilecek, güncelleyebilecek, üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek ve işleyebilecektir.
                                localination, talep etmiş olduğunuz hizmetlerin yerine getirilmesi amacıyla, alanında uzman çalışma ekibi ile hizmet almak amacıyla iletmiş olduğunuz bilgi ve taleplerinizi aracı hizmet sağlayıcı sıfatı ile üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek ve işleyebilecektir. Ancak localination olarak asıl amacımız sizin güvenliğiniz ve önceliğiniz olması sebebi ile sadece almayı talep etmiş olduğunuz hizmetlerin yerine getirilmesi amacıyla iletmiş olduğunuz bilgi ve taleplerinizi üçüncü kişilerle bilgi paylaşımı yapılacaktır. <br /> <br />

                                Kişisel verileriniz şu amaçlarla kullanılmaktadır: <br />
                                - Web sitesi/mobil uygulamalar üzerinden alışveriş yapanın/yaptıranın kimlik bilgilerini teyit etmek, <br />
                                - İletişim için adres ve diğer gerekli bilgileri kaydetmek, <br />
                                - Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında akdettiğimiz sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili müşterilerimiz ile iletişime geçmek, gerekli bilgilendirmeleri yapabilmek, <br />
                                - Elektronik (internet/mobil vs.) veya kâğıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek, <br />
                                - Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında akdettiğimiz sözleşmeler uyarınca üstlenilen yükümlülükleri ifa etmek, <br />
                                - Kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat gereği kamu görevlilerine bilgi verebilmek, <br />
                                - Müşterilerimize daha iyi bir hizmet deneyimini sağlamak, “müşterilerimizin ilgi alanlarını dikkate alarak” müşterilerimizin ilgilenebileceği hizmetlerimiz hakkında müşterilerimize bilgi verebilmek, kampanyaları aktarmak, <br />
                                - Müşteri memnuniyetini artırmak, web sitesi ve/veya mobil uygulamalardan hizmet alan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmek ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anketler düzenlemek, <br />
                                - Anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından müşterilerimize öneri sunabilmek, hizmetlerimizle ilgili müşterilerimizi bilgilendirebilmek, <br />
                                - Hizmetlerimiz ile ilgili müşteri şikâyet ve önerilerini değerlendirebilmek, <br />
                                - Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki mevzuattan doğan haklarımızı kullanabilmek, <br />

                                İşbu Web sitesinden işlem yapan müşterilerimizin verdikleri veriler, müşterilerimizin rızaları ve mevzuat hükümleri uyarınca localination tarafından işlenmektedir. <br />
                                İşbu Web sitesi ziyaretçilerine daha iyi hizmet verebilmek amacıyla ve yasal yükümlülüğü çerçevesinde, işbu Kişisel Verilerin Korunması Hakkında Açıklama metninde belirlenen amaçlar ve kapsam dışında kullanılmamak kaydı ile gezinme bilgilerinizi toplayacak, işleyecek, üçüncü kişilerle paylaşacak ve güvenli olarak saklayacaktır. Web sitemizden kredi kartı ile alışveriş yapan müşterilerimiz, IYZICO SANAL POS’unu kullanmak suretiyle; kişisel verilerini IYZICO ile paylaşmaya ve IYZICO tarafından işlenmesine rıza göstermiş sayılır. Bu bakımından, kredi kartı ile alışveriş yapmadan önce https://www.iyzico.com/gizlilik-politikasi sayfasının okunup, incelenmesi gerekir. <br />
                                İşbu Web sitesi günlük dosyaları, boş gif dosyaları ve/veya üçüncü taraf kaynakları yoluyla topladığı bilgileri tercihlerinizle ilgili bir özet oluşturmak amacıyla depolar. İşbu Web sitesi, size özel tanıtım yapmak, promosyonlar ve pazarlama teklifleri sunmak, web sitesinin içeriğini size göre iyileştirmek ve/veya tercihlerinizi belirlemek amacıyla; site üzerinde gezinme bilgilerinizi ve/veya site üzerindeki kullanım geçmişinizi izleyebilmektedir. <br />
                                İşbu Web sitesi, çevrimiçi ve çevrimdışı olarak toplanan bilgiler gibi farklı yöntemlerle veya farklı zamanlarda site üzerinde sizden toplanan bilgileri eşleştirebilir ve bu bilgileri üçüncü taraflar gibi başka kaynaklardan alınan bilgilerle birlikte kullanabilir. <br />
                                İşlenen kişisel verilerinizin yukarıda belirtilen amaçlarla aktarılabileceği kişi ya da kuruluşlar
                                Kişisel verileriniz, yukarıda atıf yapılan tüm ulusal/uluslararası mevzuatın izin verdiği ve/veya söz konusu mevzuat ya da taraf olunan sözleşmeler kapsamında kişisel bilgileri talep etme ve işleme iznine, hak ve yetkisine sahip kişi veya kuruluşlar (Adli ve idari makamlar, diğer resmi kurum ve kuruluşlar ve kişiler) başta olmak ve bunlarla sınırlı olmamak üzere, dolaylı/doğrudan yurtiçi/yurtdışı iştiraklerimiz ya da bağlı ortaklıklarımız, localination tarafından hizmet/destek/danışmanlık alınan ya da işbirliği yapılan ya da proje/program/finansman ortağı olunan yurtiçi/yurtdışı/uluslararası, kamu/özel kurum ve kuruluşlar, şirketler ve sair 3. Kişi ya da kuruluşlara aktarılabilecektir. <br /> <br />

                                Kişisel verilerin toplanma yöntemi ve hukuki sebebi <br />
                                localination ile paylaşılan kişisel veriler, localination gözetimi ve kontrolü altındadır. localination, yürürlükteki ilgili mevzuat hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri yaptırılmakta ve bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız. <br />
                                İnternet sitemiz localination 'in kontrolü altında olmayan başka internet sitelerine bağlantı veya referans içerebilir. Bu bağlantıların www.localination.com adlı internet sitesinde yer alması, bizim bu siteler ile herhangi bir bağımız bulunduğu anlamına gelmez. localination, işbu internet sitelerinin içerikleri veya içerdikleri diğer bağlantılardan sorumlu değildir. Bu diğer internet siteleri kullanıcıların bilgisayarına kendi çerezlerini yükleyebilir ve bilgi toplayabilirler. Diğer internet siteleri kendi gizlilik ve kullanım sözleşmelerine sahiptir ve kullanıcılar tarafından paylaşılan bilgileri farklı amaçlar için kullanabilirler. localination'ın tavsiyesi diğer internet sitelerini kullanmadan önce kullanıcıların, sözleşmelerini okumalarıdır. Söz konusu siteler de, sizin kişisel bilgilerinizi toplayıp, işleyebilir. Bu gibi durumlarda; localination, kendi dışındaki web sitelerinin sizin kişisel verilerinizi edinip, işlemesinden dolayı herhangi bir sorumluğu bulunmamaktadır. Bu amaçla, sizin; ilgili web sitelerinin gizlilik ve kişisel verilerin korunması ile ilgili sayfalarını okumanız gerekmektedir.
                                Yukarıda açıklanan hukuki sebeplerle, kişisel verileriniz localination’ın internet sitesi ve benzeri diğer tüm kanalları aracılığıyla otomatik ya da otomatik olmayan yollarla sözlü, yazılı veya elektronik olarak toplanabilmektedir. <br />
                                KVKK’nın “İstisnalar” başlıklı md. 28’de öngörülen haller saklı kalmak kaydıyla, anılan Kanun’un md. 11 kapsamında sahip olunan haklar: <br />
                                KVKK uyarınca kişisel verilerinizin; <br />
                                İşlenip işlenmediğini öğrenme, <br />
                                İşlenmişse bilgi talep etme, <br />
                                İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme, <br />
                                Yurt içinde/yurt dışında aktarıldığı 3. kişileri bilme, <br />
                                Eksik/yanlış işlenmişse düzeltilmesini isteme, <br />
                                KVKK md. 7’de öngörülen şartlar çerçevesinde silinmesini/yok edilmesini isteme, <br />
                                Aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme, <br />
                                Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme, <br />
                                KVKK’ya aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarına sahip olduğunuzu hatırlatmak isteriz. <br />
                                localination’a başvurarak kişisel verilerinizle ilgili bu bilgileri almaya hak ve yetkinizin olduğunu hatırlatırız. Bu taleplerinizin yerine getirilmesini teminen, localination tarafından yapılacak masrafları, KVKK’nın “Veri sorumlusuna başvuru” başlıklı md. 13’de belirtilen tarifeye göre tarafınızdan talep etme hakkımız saklıdır.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
