import { Column } from 'react-table'
import { ReferenceSelectionHeader } from './ReferenceSelectionHeader'
import { ReferenceSelectionCell } from './ReferenceSelectionCell'
import { ReferenceCustomHeader } from './ReferenceCustomHeader'
import { ReferenceInfoCell } from './ReferenceInfoCell'
import { ReferenceActionsCell } from './ReferenceActionsCell'
import { ReferenceImageCell } from './ReferenceImageCell'
import { ReferenceContentCell } from './ReferenceContentCell'
import { Reference } from '../reference-list/core/_models'


const usersColumns: ReadonlyArray<Column<Reference>> = [
  // {
  //   Header: (props) => <BlogSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <BlogSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <ReferenceCustomHeader tableProps={props} title='Resim' className='min-w-125px' />,
    id: 'image',
    Cell: ({ ...props }) => <ReferenceImageCell Image={props.data[props.row.index].image} />,
  },
  {
    Header: (props) => <ReferenceCustomHeader tableProps={props} title='Logo' className='min-w-125px' />,
    id: 'logo',
    Cell: ({ ...props }) => <ReferenceImageCell Image={props.data[props.row.index].logo} />,
  },
  {
    Header: (props) => <ReferenceCustomHeader tableProps={props} title='Başlık' className='min-w-125px' />,
    accessor: 'title',
  },
  {
    Header: (props) => <ReferenceCustomHeader tableProps={props} title='Alt Yapı' className='min-w-125px' />,
    accessor: 'substructure',
  },
  {
    Header: (props) => <ReferenceCustomHeader tableProps={props} title='Kategori' className='min-w-125px' />,
    accessor: 'category',
  },
  {
    Header: (props) => <ReferenceCustomHeader tableProps={props} title='Web Site' className='min-w-125px' />,
    accessor: 'site',
  },
  {
    Header: (props) => <ReferenceCustomHeader tableProps={props} title='Oluşturulma Tarihi' className='min-w-125px' />,
    accessor: 'cr_date',
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader tableProps={props} title='Oluşturan' className='min-w-125px' />
    ),
    accessor: 'fullname',
  },
  {
    Header: (props) => (
      <ReferenceCustomHeader tableProps={props} title='Aksiyonlar' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ReferenceActionsCell id={props.data[props.row.index].id} />,
  },
]

export { usersColumns }
