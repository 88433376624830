/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { Blog } from '../blog-list/core/_models'

type Props = {
  Image: any
}

const BlogImageCell: FC<Props> = ({ Image }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <div className=' symbol symbol-50px'>
        <img className='img-fluid' width={50} height={50} src={`${process.env.REACT_APP_MEDIA_API_URL}blogs/${Image}`}></img>
      </div>
    </div>
  </div>
)

export { BlogImageCell }
