/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Setting } from '../settings-list/core/_models'
import { editPopUp } from '../settings-list/core/_requests'


type Props = {
  user: Setting
}

const SettingsInfoCell: FC<Props> = ({ user }) => {
  const editPopup = async () => {
    const myData = {active: !user.active, id: user?.id }
    await editPopUp(myData).then((response: any) => {
      if (response.islem === "basarili") {
        setActive(myData.active)
        window.location.reload()
      }
    })
  }
  const [active, setActive] = useState<any>(false)
  useEffect(() => {
    if (user.active) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [user])

  return (
    <div className='d-flex align-items-center'>
      <div className="form-check form-switch form-check-custom form-check-solid">
        <input className="form-check-input" type="checkbox" checked={active} onChange={() => { editPopup() }} id="flexSwitchDefault" />
        <label className="form-check-label" htmlFor="flexSwitchDefault">
        </label>
      </div>
      <div className='d-flex flex-column'>
        {active ? <span>Aktif</span> : <span>Aktif değil</span>}

      </div>
    </div>
  )
}

export { SettingsInfoCell }
