import {useEffect} from 'react'
import { SettingsModalHeader } from './SettingsModalHeader'
import { SettingsEditModalFormWrapper } from './SettingsEditModalFormWrapper'
import { useListView } from '../settings-list/core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { useQuery } from 'react-query'
import { getPopUpDetails } from '../settings-list/core/_requests'


const SettingsEditModal = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.POP_UP_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPopUpDetails(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {!itemIdForUpdate ? 
            <> 
            <SettingsModalHeader isUserLoading={isLoading} user={{id: undefined}}/>
            </>
            :
            !isLoading && !error && user ? 
            <>
            <SettingsModalHeader isUserLoading={isLoading} user={user}/>
            </>
            :
            <>
            </>
            }
            {/* begin::Modal body */}
            <div className='modal-body scroll-y'>
              <SettingsEditModalFormWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {SettingsEditModal}
