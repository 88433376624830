/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { ID, KTSVG, QUERIES } from '../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { deletePopUp } from '../settings-list/core/_requests'
import { useQueryResponse } from '../settings-list/core/QueryResponseProvider'
import { useListView } from '../settings-list/core/ListViewProvider'



type Props = {
  id: ID
}

const SettingsActionsCell: FC<Props> = ({ id }) => {

  const { setItemIdForUpdate,setUpdateOrReview} = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (deger:any) => {
    if(deger===2){
      setUpdateOrReview(true)
      setItemIdForUpdate(id)
    }else{
      setItemIdForUpdate(id)
      setUpdateOrReview(false)
    }
    
  }
  

  const deleteItem = useMutation(() => deletePopUp(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.BLOG_LIST}-${query}`])
    },
  })
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Aksiyonlar
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            onClick={() => {openEditModal(1)}}
            className='menu-link px-3'>
            İncele
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            onClick={() => {openEditModal(2)}}
            className='menu-link px-3'>
            Güncelle
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Sil
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { SettingsActionsCell }
