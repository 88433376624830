/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
// import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain'
// import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {useState} from 'react'

export function AsideMenuMain() {
  const {currentUser} = useAuth()
  const intl = useIntl()
  const panel_url = 'http://localhost:3000/'
  const PANEL_URL = panel_url + '/about-us'
  const [pathName, setPathName] = useState(PANEL_URL)

  return (
    <>
      {currentUser?.authority === 1 ? (
        <>
          <AsideMenuItem
            to='/dashboard'
            title='AnaSayfa'
            fontIcon='bi-house fs-2'
            bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            className='py-2'
          />
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Profilim'
            fontIcon='bi bi-person-circle'
          />
          <AsideMenuItem
            to='/apps/user-management/customers'
            title='Üyeler'
            fontIcon='bi bi-people-fill'
          />
          <AsideMenuItem
            to='/apps/user-management/offers'
            title='Teklifler'
            fontIcon='bi bi-card-list'
          />
          <AsideMenuItem to='/blog' title='Bloglar' fontIcon='bi bi-chat-left' />
          <AsideMenuItem to='/proje' title='Projeler' fontIcon='bi bi-laptop' />
          <AsideMenuItem to='/referans' title='Referanslar' fontIcon='bi bi-link' />

          <AsideMenuItemWithSubMain
            to='/ayarlar'
            title='Ayarlar'
            fontIcon='bi bi-gear-fill'
            bsTitle='Ayarlar'
          >
            <AsideMenuItem to='/ayarlar' title='Pop Up' bsTitle='Pop Up' hasBullet={true} />
          </AsideMenuItemWithSubMain>
        </>
      ) : (
        <>
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Profilim'
            fontIcon='bi bi-person-circle'
          />
          <AsideMenuItem
            to='/apps/user-management/offers'
            title='Tekliflerim'
            fontIcon='bi bi-people-fill'
          />
          <AsideMenuItem
            to={`${process.env.REACT_APP_SITE_URL}/about-us`}
            title='Teklif&nbsp;Al'
            fontIcon='bi bi-cart-plus'
            outside={true}
          />
        </>
      )}
    </>
  )
}
