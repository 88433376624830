/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, register, GetCities } from '../core/_requests'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import 'react-phone-input-2/lib/style.css'
import './custom.css'
import PhoneInput from 'react-phone-input-2'
import { GetTowns } from '../../apps/user-management/customers-list/core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { GetCountries } from '../../wizards/components/core/_requests'
import { KvkkModal } from './KvkkModal'
import { KullanimModal } from './KullanimModal'



const initialValues = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  password: '',
  changepassword: '',
  city: '',
  town: '',
  status: '',
  companyName: '',
  tax_office: '',
  acceptTerms: false,
  acceptKvkk: false,
  taxNo: '',
  country: ''
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('İsim zorunludur'),
  country: Yup.string()
    .required('Ülke zorunludur'),
  city: Yup.string()
    .required('Şehir zorunludur'),
  town: Yup.string()
    .required('İlçe zorunludur'),
  status: Yup.string()
    .required('Statü zorunludur'),
  email: Yup.string()
    .email('Geçerli bir e-posta giriniz')
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('E-posta zorunludur'),
  surname: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Soyad zorunludur'),
  phone: Yup.string()
    .min(12, 'Minimum 12 karakter')
    .max(12, 'Maksimum 12 karakter')
    .required('Telefon numaranız zorunludur'),
  password: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Parola zorunludur'),
  changepassword: Yup.string()
    .required('Parolanızı doğrulamanız zorunludur')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Parola ve doğrulanmış parola uyuşmuyor"),
    }),
  acceptTerms: Yup.bool().required('Şartları ve Koşulları kabul etmeniz gerekir'),
  acceptKvkk: Yup.boolean()
      .required('KVKK bilgindirme metinini onaylamanız gerekir')
      
})

export function Registration() {
  const [kvkkModal, setKvkkModal] = useState(false);
  const [kullanimModal, setKullanimModal] = useState(false);
  const [cities, setCities] = useState([])
  const [towns, SetTowns] = useState([])
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(false)
  const [isCompany, setIsCompany] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const registrationSchemaCompany = Yup.object().shape({
    tax_office: Yup.string()
      .required('Vergi Dairesi zorunludur')
      .min(3, 'Minimum 3 karakter'),
    companyName: Yup.string()
      .required('Kurum adı zorunludur')
      .min(3, 'Minimum 3 karakter'),
    taxNo: Yup.string()
      .min(10, 'Minimum 10 karakter')
      .max(10, 'Maksimum 10 karakter')
      .required('Vergi numaranız zorunludur'),
  })
  const registrationSchemaSon = useMemo(() => {
    if (isCompany) {
      return registrationSchema.concat(registrationSchemaCompany)
    } else {
      return registrationSchema;
    }
  }, [isCompany])


  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchemaSon,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {

        const { data: auth } = await register(
          values
        )
        if (auth.islem === "basarili") {
          saveAuth(auth)
          const { data: user } = await getUserByToken(auth.api_token)
          setCurrentUser(user)
        } else if (auth.islem === "hatali") {
          console.error(auth.hata)
          saveAuth(undefined)
          setStatus(auth.hata)
          setSubmitting(false)
          setLoading(false)
        } else {
          console.error("Hata")
          saveAuth(undefined)
          setStatus('Kayıt bilgileri yanlış')
          setSubmitting(false)
          setLoading(false)
        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Kayıt bilgileri yanlış')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const GetCityLists = async (id: any) => {
    await GetCities(Number(id)).then((response: any) => {
      setCities(response.data)
    })
  }
  const GetTownLists = async (city_id: string) => {
    await GetTowns(Number(city_id)).then((response) => {
      SetTowns(response.data)
    })
  }

  const GetCountryLists = async () => {
    await GetCountries().then((response) => {
      setCountries(response.data)
    })
  }
  useEffect(() => {
    if (formik.getFieldProps('status').value === "1") {
      setIsCompany(true);
    } else {
      setIsCompany(false);
    }
  }, [formik.getFieldProps('status').value])
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const stt = (e: any) => {
    //formik.getFieldProps('phone').onChange(e)
    formik.setFieldValue("phone", e)
    formik.setTouched({ ...formik.touched, ["phone"]: true });
  };

  useEffect(() => {
    if (parseInt(formik.values.status) === 1) {
    }
  }, [formik.values])

  const handleClick = () => {
  }
  useEffect(() => {
    GetCountryLists();
  }, [])

  useEffect(() => {
    GetCityLists(formik.values.country);
  }, [formik.values.country])

  useEffect(() => {
    GetTownLists(formik.values.city)
  }, [formik.values.city])
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='d-flex flex-column flex-center py-0 px-5 px-md-15 w-100'>
        {/* begin::Logo */}
        <Link to='/' className=''>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/PNG.png')} style={{ height: "200px" }} />
        </Link>
      </div>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Kayıt Ol</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Sosyal Kampanyalarınız</div>
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>İsim</label>
        <input
          placeholder='İsminizi giriniz'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Soyad</label>
        <input
          placeholder='Soyadınızı giriniz'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('surname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.surname && formik.errors.surname,
            },
            {
              'is-valid': formik.touched.surname && !formik.errors.surname,
            }
          )}
        />
        {formik.touched.surname && formik.errors.surname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.surname}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>E-Posta</label>
        <input
          placeholder='E-postanızı giriniz'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }

          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6' htmlFor="inputState">Ülke</label>
        <select
          id="inputState"
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.country && formik.errors.country },
            {
              'is-valid': formik.touched.country && !formik.errors.country,
            }
          )}
          {...formik.getFieldProps('country')}>
          <option hidden value={""}>Ülke seç</option>
          {countries.map((c: any, i) => {
            return (
              <option key={i} value={c.value} >{c.label}</option>
            )
          })}

        </select>
        {formik.touched.country && formik.errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group City */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6' htmlFor="inputState">Şehir</label>
        <select
          id="inputState"
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.city && formik.errors.city },
            {
              'is-valid': formik.touched.city && !formik.errors.city,
            }
          )}
          {...formik.getFieldProps('city')}>
          <option hidden value={""}>Şehir seç</option>
          {cities.map((c: any, i) => {
            return (
              <option key={i} value={c.value} >{c.label}</option>
            )
          })}

        </select>
        {formik.touched.city && formik.errors.city && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.city}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group town */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6' htmlFor="inputState">İlçe</label>
        <select
          id="inputState"
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.town && formik.errors.town },
            {
              'is-valid': formik.touched.town && !formik.errors.town,
            }
          )}
          {...formik.getFieldProps('town')}>
          <option hidden value={""}>İlçe seç</option>
          {towns.map((c: any, i) => {
            return (
              <option key={i} value={c.value} >{c.baslik}</option>
            )
          })}

        </select>
        {formik.touched.town && formik.errors.town && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.town}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Status */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6' htmlFor="inputState" >Statü</label>
        <select id="inputState" className={clsx(
          'form-control bg-transparent',
          { 'is-invalid': formik.touched.status && formik.errors.status },
          {
            'is-valid': formik.touched.status && !formik.errors.status,
          }
        )
        } {...formik.getFieldProps('status')}>
          <option value={""} hidden>Statü seç</option>
          <option value={0}>Bireysel</option>
          <option value={1}>Kurumsal</option>

        </select>
        {formik.touched.status && formik.errors.status && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.status}</span>
            </div>
          </div>
        )}
      </div>
      {formik.getFieldProps('status').value === "1" ?
        <>
          <div className='fv-row mb-8 '>
            <label className='form-label fw-bolder text-dark fs-6'>Kurum Adı</label>
            <input
              placeholder='Kurum Adını Giriniz'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('companyName')}
              className={clsx(
                'form-control bg-transparent ',
                { 'is-invalid': formik.touched.companyName && formik.errors.companyName },
                {
                  'is-valid': formik.touched.companyName && !formik.errors.companyName,
                }

              )}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.companyName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8 '>
            <label className='form-label fw-bolder text-dark fs-6'>Vergi Dairesi</label>
            <input
              placeholder='Vergi Dairesi Giriniz'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('tax_office')}
              className={clsx(
                'form-control bg-transparent ',
                { 'is-invalid': formik.touched.tax_office && formik.errors.tax_office },
                {
                  'is-valid': formik.touched.tax_office && !formik.errors.tax_office,
                }

              )}
            />
            {formik.touched.tax_office && formik.errors.tax_office && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.tax_office}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8 '>
            <label className='form-label fw-bolder text-dark fs-6'>Vergi No</label>
            <input
              placeholder='Vergi Numaranızı Giriniz'
              type='number'
              autoComplete='off'
              {...formik.getFieldProps('taxNo')}
              className={clsx(
                'numberinput form-control bg-transparent ',
                {
                  'is-invalid': formik.touched.taxNo && formik.errors.taxNo
                },
                {
                  'is-valid': formik.touched.taxNo && !formik.errors.taxNo,
                }

              )}
            />
            {formik.touched.taxNo && formik.errors.taxNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.taxNo}</span>
                </div>
              </div>
            )}
          </div>
        </>
        : <></>}

      {/* end::Form group */}

      {/* begin::Form group Phone */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Telefon {formik.touched.phone ? "1" : "0"}</label>
        <PhoneInput
          country={'tr'}
          inputClass={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.phone && formik.errors.phone,
            },
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
          {...formik.getFieldProps('phone')}
          onChange={(e: any) => (stt(e))}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Parola</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Parola'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          8 veya daha fazla Harf, sayı ve karakterden oluşan parola kullanın.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Parolanızı doğrulayınız</label>
        <input
          type='password'
          placeholder='Parolanızı doğrulayınız'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}



      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptKvkk')}
          />
          <span>
            <button
              type='button'
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_1"
              className='ms-1 link-primary'
              style={{ border: "none", backgroundColor: "transparent" }}> (Kişisel verilerin korunması kanunu kapsamındaki bilgilendirme metnini)
            </button>
            {" "}
            okudum ve kabul diyorum
            .
          </span>
        </label>
        {formik.touched.acceptKvkk && formik.errors.acceptKvkk && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptKvkk}</span>
            </div>
          </div>
        )}
        <KvkkModal />
      </div>
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            <button
              type='button'
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_2"
              className='link-primary'
              style={{ border: "none", backgroundColor: "transparent" }}>(Üyelik ve kullanım şartları sözleşmesini) 
            </button>
            {" "}
            okudum ve kabul diyorum.
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
        <KvkkModal />
        <KullanimModal/>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms || !formik.values.acceptKvkk}
          onClick={() => handleClick()}
        >
          {!loading && <span className='indicator-label'>Kaydol</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Lütfen bekleyin...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            İptal
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
