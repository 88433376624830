import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Reference, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_REFERENCE_URL = `${API_URL}contentTransactions/reference/List`
const GET_REFERENCE_DETAIL_URL = `${API_URL}contentTransactions/reference/Detail`
const ADD_REFERENCE_URL = `${API_URL}contentTransactions/reference/Add`
const EDİT_REFERENCE_URL = `${API_URL}contentTransactions/reference/Edit`
const DELETE_REFERENCE_URL = `${API_URL}contentTransactions/reference/Delete`


const getReference = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_REFERENCE_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getReferenceDetails = (id: ID): Promise<Reference | undefined> => {
  const all = axios
    .post(`${GET_REFERENCE_DETAIL_URL}/${id}`)
    .then((response: Response<Reference>) => response.data)
    return all;
}

const addReference = (user: Reference): Promise<Reference | undefined> => {
  const all = axios
  .post(ADD_REFERENCE_URL, user)
  .then((response: Response<Reference>) => response.data)
  return all
}

const editReference = (user: Reference): Promise<Reference | undefined> => {
  return axios
    .post(`${EDİT_REFERENCE_URL}/${user.id}`, user)
    .then((response: Response<Reference>) => response.data)
}

const deleteReference= (userId: ID): Promise<void> => {
  return axios.delete(`${DELETE_REFERENCE_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${DELETE_REFERENCE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}



export {deleteReference, getReference, deleteSelectedUsers, getReferenceDetails, addReference, editReference}
