import {ID, Response} from '../../../../../../_metronic/helpers'
export type Reference = {
  id?: ID
  image?: string
  subtitle?:string
  title?: string
  logo?: string
  substructure ?: string
  category ?: string
  site ?: string
  content_image?:string
  problem_content?: string
  solution_content?: string
  result_content?: string
  list_text?:string
  summary_text?: string
  content?: string
  fullname?: string
  resim?: string
  header?: string
  tags?: string[]
  cr_date?: string
  images?: []
  user?:string
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  component?:string
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<Reference>>

export const initialUser: Reference = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
  images: []
}
