import { KTCard } from "../../../../../_metronic/helpers"
import { SettingsTable } from "../table/SettingsTable"
import { SettingsEditModal } from "../user-edit-modal/SettingsEditModal"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { SettingsListHeader } from "./header/SettingsListHeader"


const SettingsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <SettingsListHeader />
        <SettingsTable/>
      </KTCard>
      {itemIdForUpdate !== undefined && <SettingsEditModal />}
    </>
  )
}

const SettingsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SettingsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SettingsListWrapper}
