import { Column } from 'react-table'

import { Setting } from '../settings-list/core/_models'
import { SettingsActionsCell } from './SettingsActionsCell'
import { SettingsCustomHeader } from './SettingsCustomHeader'
import { SettingsImageCell } from './SettingsImageCell'
import { SettingsInfoCell } from './SettingsInfoCell'


const usersColumns: ReadonlyArray<Column<Setting>> = [
  {
    Header: (props) => <SettingsCustomHeader tableProps={props} title='Pop-Up Başlık' className='min-w-125px' />,
    accessor: 'title',
  },
  {
    Header: (props) => <SettingsCustomHeader tableProps={props} title='Aktif' className='min-w-125px' />,
    id: 'active',
    Cell: ({...props}) => <SettingsInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SettingsCustomHeader tableProps={props} title='Oluşturulma Tarihi' className='min-w-125px' />,
    accessor: 'cr_date',
  },
  {
    Header: (props) => (
      <SettingsCustomHeader tableProps={props} title='Oluşturan' className='min-w-125px' />
    ),
    accessor: 'fullname',
  },
  {
    Header: (props) => (
      <SettingsCustomHeader tableProps={props} title='Aksiyonlar' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <SettingsActionsCell id={props.data[props.row.index].id} />,
  },
]

export { usersColumns }
