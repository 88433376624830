import { Column } from 'react-table'
import { Blog } from '../blog-list/core/_models'
import { BlogSelectionHeader } from './BlogSelectionHeader'
import { BlogSelectionCell } from './BlogSelectionCell'
import { BlogCustomHeader } from './BlogCustomHeader'
import { BlogInfoCell } from './BlogInfoCell'
import { BlogActionsCell } from './BlogActionsCell'
import { BlogImageCell } from './BlogImageCell'
import { BlogContentCell } from './BlogContentCell'


const usersColumns: ReadonlyArray<Column<Blog>> = [
  // {
  //   Header: (props) => <BlogSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <BlogSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <BlogCustomHeader tableProps={props} title='Resim' className='min-w-125px' />,
    id: 'image',
    Cell: ({ ...props }) => <BlogImageCell Image={props.data[props.row.index].image} />,
  },
  {
    Header: (props) => <BlogCustomHeader tableProps={props} title='İçerik Resmi' className='min-w-125px' />,
    id: 'content_image',
    Cell: ({ ...props }) => <BlogImageCell Image={props.data[props.row.index].content_image} />,
  },
  {
    Header: (props) => <BlogCustomHeader tableProps={props} title='Başlık' className='min-w-125px' />,
    accessor: 'title',
  },
  {
    Header: (props) => <BlogCustomHeader tableProps={props} title='Alt Başlık' className='min-w-125px' />,
    accessor: 'subtitle',
  },
  {
    Header: (props) => <BlogCustomHeader tableProps={props} title='Oluşturulma Tarihi' className='min-w-125px' />,
    accessor: 'cr_date',
  },
  {
    Header: (props) => (
      <BlogCustomHeader tableProps={props} title='Oluşturan' className='min-w-125px' />
    ),
    accessor: 'fullname',
  },
  {
    Header: (props) => (
      <BlogCustomHeader tableProps={props} title='Aksiyonlar' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <BlogActionsCell id={props.data[props.row.index].id} />,
  },
]

export { usersColumns }
