import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import { IProfileDetails, IUpdateEmail, IUpdatePassword } from '../../../../accounts/components/settings/SettingsModel'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}userTransactions/userList`
const GET_USERS_DETAİL_URL = `${API_URL}userTransactions/userDetails`
const UPDATE_USERS_DETAİL_URL = `${API_URL}userTransactions/editUser`
const LİST_COUNTRY_URL = `${API_URL}GuestController/listCountry`
const LİST_TOWN_URL = `${API_URL}GuestController/listTown`
const LİST_CİTY_URL = `${API_URL}GuestController/listCity`
const CHANGE_EMAİL_URL = `${API_URL}userTransactions/changeMail`
const CHANGE_PASSWORD_URL = `${API_URL}userTransactions/changePassword`
const ADD_NOTES = `${API_URL}contentTransactions/userNotes/Add`
const GET_NOTES = `${API_URL}contentTransactions/userNotes/List`
const DELETE_NOTES = `${API_URL}contentTransactions/userNotes/Delete`
const HOME_PAGE_INFO = `${API_URL}contentTransactions/homePageInfo`
const GET_LOGS = `${API_URL}logTransactions/getUserLogs`


const getHomePageInfo = () => {
  return axios.get(HOME_PAGE_INFO)
}

const addNotes = (notes:any) => {
  const all = axios
  .post(ADD_NOTES, notes)
  .then((response: any) => response.data)
  return all
} 

const getNotes = (user_id:any) => {
  return axios
    .get(`${GET_NOTES}/${user_id}`)
    .then((d: any) => d.data)
}

const deleteNotes = (id: any)=> {
  return axios
    .get(`${DELETE_NOTES}/${id}`)
    .then((d: any) => d.data)
}

const getLogs = (offer_id: any) => {
  return axios.get(`${GET_LOGS}/${offer_id}`)
}

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}



const getUserById = (id: ID): Promise<any> => {
  const all= axios
    .get(`${GET_USERS_DETAİL_URL}/${id}`)
    .then((response: Response<User>) => response.data)
  return all
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: IProfileDetails): Promise<IProfileDetails | undefined> => {
  const add = axios
    .post(`${UPDATE_USERS_DETAİL_URL}`, user)
    .then((response: Response<IProfileDetails>) => response.data)
  return add
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export function GetCountries() {
  return axios.get(LİST_COUNTRY_URL)
}

export function GetTowns(id: ID) {
    return axios.get(LİST_TOWN_URL+'/'+id)
}

export function GetCities(country_id=223) {
    return axios.get(LİST_CİTY_URL+'/'+country_id)
}

const passwordChange = (user: IUpdatePassword): Promise<IUpdatePassword | undefined> => {
  return axios
    .post(CHANGE_PASSWORD_URL, user)
    .then((response: Response<IUpdatePassword>) => response.data)
}

const emailChange = (user: IUpdateEmail): Promise<IUpdateEmail | undefined> => {
  return axios
    .post(CHANGE_EMAİL_URL, user)
    .then((response: Response<IUpdateEmail>) => response.data)
}


export {getLogs,getHomePageInfo,addNotes, getNotes, deleteNotes, getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser , passwordChange ,emailChange}
