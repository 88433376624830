import { Column } from 'react-table'
import { Project } from '../project-list/core/_models'
import { ProjectSelectionHeader } from './ProjectSelectionHeader'
import { ProjectSelectionCell } from './ProjectSelectionCell'
import { ProjectCustomHeader } from './ProjectCustomHeader'
import { ProjectInfoCell } from './ProjectInfoCell'
import { ProjectActionsCell } from './ProjectActionsCell'
import { ProjectImageCell } from './ProjectImageCell'
import { ProjectContentCell } from './ProjectContentCell'


const usersColumns: ReadonlyArray<Column<Project>> = [
  // {
  //   Header: (props) => <BlogSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <BlogSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Resim' className='min-w-125px' />,
    id: 'image',
    Cell: ({ ...props }) => <ProjectImageCell Image={props.data[props.row.index].image} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='İçerik Resmi' className='min-w-125px' />,
    id: 'content_image',
    Cell: ({ ...props }) => <ProjectImageCell Image={props.data[props.row.index].content_image} />,
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Başlık' className='min-w-125px' />,
    accessor: 'title',
  },
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Alt Başlık' className='min-w-125px' />,
    accessor: 'subtitle',
  },
  
  {
    Header: (props) => <ProjectCustomHeader tableProps={props} title='Oluşturulma Tarihi' className='min-w-125px' />,
    accessor: 'cr_date',
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Oluşturan' className='min-w-125px' />
    ),
    accessor: 'fullname',
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Aksiyonlar' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ProjectActionsCell id={props.data[props.row.index].id} />,
  },
]

export { usersColumns }
