import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import { Reference } from '../reference-list/core/_models'


type Props = {
  column: ColumnInstance<Reference>
}

const ReferenceHeaderColumn: FC<Props> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

export {ReferenceHeaderColumn}
