import React, { useEffect } from 'react'

export const KullanimModal = () => {

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id="kt_modal_2">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Üyelik ve Kullanım Şartları Sözleşmesi</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>Kullanım Koşulları ve Üyelik Sözleşmesi <br />
                                1. Taraflar <br />
                                İşbu Kullanım Koşulları, Üyelik Sözleşmesi (Sözleşme) bir tarafta, DEMİRTAŞ CUMHURİYET MAH. 3. CAD. YASEMİN PARK G BLOK NO: 3 /22 İÇ KAPI NO: 13 OSMANGAZİ/ BURSA adresinde faaliyet gösteren, 6563 sy. Elektronik Ticaretin Düzenlemesi Hakkında Kanuna göre faaliyette bulunan şirket adı (şirket unvanı) ile Kullanıcı, Üye veya Ziyaretçi olarak www.localination.com alan adlı web sitesini kullanan “Kullanıcı” arasında akdedilmiştir. <br />
                                2. Sözleşmenin Amacı <br />
                                2.1. Furkan Kurt (localination)’ın sahibi olduğu www.localination.com alan adlı internet sitesinde (‘Web’ veya ‘Web Sitesi’), Furkan Kurt (localination)’ın Kullanıcıya sunacağı ürün ve hizmetlerin hangi şartlarda sunulacağını belirler. <br />
                                2.2. Furkan Kurt (localination), Kullanıcılarına hizmet sağlayan bir elektronik ticaret servisidir. Bu hizmetler www.localination.com alan adı üzerinde yayın yapan internet sitesi üzerinden sağlanmaktadır. <br />
                                2.3. İşbu kullanım koşulları (sözleşme), Furkan Kurt (localination) web sitesini kullanan, ziyaret eden ve/veya site üzerinden, internet ortamında hizmet talebinde bulunan tüm gerçek ve tüzel kişiler ile tüketiciler bakımından geçerlidir. <br />
                                2.4. Furkan Kurt (localination)’ın, bu web sitesinde konu ettiği hizmetler, site içerisinde kategorilere ayrılan ve talep üzerine gerçekleştirilmesi planlanan hizmetleri kapsamaktadır. Söz konusu hizmetlerden gerçek ve gerçek kişi olmayan tüzel kişiler ile tüketiciler yararlanabilir. Bu bakımdan; kullanım koşulları’nda yer alan bazı hükümlerin, tüketiciler bakımından hukuken geçerliliği bulunmayabilir. Bu durumda, hazırlanmış sözleşmelerin (Ön bilgilendirme formları, Satış sözleşmeleri) okunması ve onaylanması gerekir. <br />
                                Tüketici: Ticari veya mesleki olmayan amaçlarla hareket eden gerçek veya tüzel kişi
                                Lütfen tabi olduğunuz ilgili sözleşmeye ve/veya bildirime (Mesafeli Satış Sözleşmelerine) bakınız. <br />
                                2.5. Tüketici işlemi olmayan hizmet taleplerinde; kullanıcı, siparişini vermeden, sözleşmeyi kabul etmeden önce aşağıdaki hususlarda bilgilendirildiğini ve bunları kabul ettiğini beyan ve taahhüt eder. <br />
                                a) Sözleşmenin kurulabilmesi için izlenecek teknik adımlara ilişkin bilgilere kendisi tarafından kolaylıkla ulaşılabileceğini, bu tür teknik bilgilerin kendisine ayrıca verilmesine gerek olmadığı; <br />
                                b) Sözleşme metni, sözleşmenin kurulmasından sonra Furkan Kurt (localination) tarafından 3 yıl süre ile saklanacak olup; Alıcı’nın, bu sözleşme metnine, siparişi verip ödemeyi yaptıktan itibaren de erişiminin mümkün olacağı; <br />
                                c) Veri girişindeki hataların açık ve anlaşılır bir şekilde belirlenmesi ve düzeltilmesine ilişkin teknik araçlara ilişkin Web sitesinde gerekli bilgiler verilmiş olup; bu konuda özel bir bilgilendirme ihtiyacına gerek olmadığı, <br />
                                d) Uygulanan gizlilik kuralları Web Sitesi’nin ilgili bölümlerinde yer almakta olup; bu konuda özel bir bilgilendirme ihtiyacına gerek olmadığı, <br />
                                e) Alternatif uyuşmazlık çözüm mekanizmaları hakkında bilgilendirmeye gerek olmadığı <br />
                                f) Furkan Kurt (localination)’in mensubu olduğu meslek odası ile meslekle ilgili davranış kuralları hakkında bilgi sahibi olduğunu, bu nedenle bunlara dair elektronik ortamda ulaşılabilme ihtiyacı olmadığı. <br />
                                2.6. Web sitesinin kullanılması, Kullanıcı tarafından işbu sözleşmenin okunduğunu ve kabul edildiğini gösterir. Bu nedenle işbu sözleşme www.localination.com  alan adlı internet sitesinden (Web Sitesi) faydalanan kullanıcılar, ziyaretçiler ve üyeler için bağlayıcıdır. Bu internet sitesini kullananlar, ister ziyaretçi, ister üye olsunlar bu sözleşme hükümlerince hareket etmeyi taahhüt etmiş demektir. Sözleşme metninde geçen "kullanıcı" kelimesi, "üye"yi ve "ziyaretçi"leri de içerir.
                                Bu web sitesine erişerek, görüntüleyerek veya kullanarak, bu koşulları okuduğunuzu, anladığınızı ve bu koşullara bağlı ve geçerli tüm yasalara uyacağınızı kabul etmektesiniz.
                                Bu koşulları kabul etmiyorsanız, lütfen bu web sitesini kullanmayın. <br />
                                3. Kullanıcı'nın Yükümlülükleri <br />
                                a) Kullanıcı, işbu sözleşmenin tamamını okuduğunu, içeriğini tamamen anladığını ve sözleşmede belirtilen ve www.localination.com alan adlı Web sitesindeki tüm hususları kayıtsız ve şartsız olarak kabul ettiğini ve onayladığını kabul ettiğini beyan ve taahhüt etmektedir. <br />
                                b) Kullanıcı, Furkan Kurt (localination) tarafından sitede sunulan hizmeti hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edecek şekilde, yasalara aykırı bir amaç için, başkalarının fikri veya sınai haklarına (telif, marka veya patent, vs.) tecavüz edecek şekilde kullanamaz. Ayrıca başkalarının www.localination.com üzerinden verilen hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet ve işlerde bulunamaz. Kullanıcı, yukarıda belirtilen kısıtlamalara uymayı şimdiden kabul ve taahhüt eder. <br />
                                c) Kullanıcı, Furkan Kurt (localination) tarafından kendisine verilmiş olan ya da kendisinin belirlediği şifreyi başka kişi ya da kuruluşlara veremez. Kullanıcı'nın, işbu sözleşme ile sahip olduğu kullanım hakları sadece kendisine aittir, başkalarına devredilemez. Kullanıcı adı ve/veya şifrenin başka kişiler tarafından kullanılmasından üye sorumlu olup, Furkan Kurt (localination)’ı bu nedenle herhangi bir şekilde sorumlu tutamaz. <br />
                                d) Kullanıcı, Furkan Kurt (localination)’a üye olurken belirlediği kullanıcı adı ve şifrenin güvenliğini sağlamanın kendi sorumluluğu altında olduğunu, gereken önlemleri almayı, diğer üyelerin üyelik hesaplarını, kullanıcı adlarını, şifrelerini kullanmamayı, verilerine izinsiz olarak ulaşmamayı ve kendilerine ait şifreleri başkalarıyla paylaşmamayı, aksi halde bundan doğacak hukuki ve cezai sorumlukların kendisine ait olacağını; üye hesaplarının yetkisi olmayanlar tarafından kullanıldığını veya şifrelerinin başkalarının eline geçtiğini öğrendiklerinde bu durumu derhal Furkan Kurt (localination)'a bildirmeyi; Furkan Kurt (localination)’ın üyelerin şifrelerinin, üyelik hesaplarının, kullanıcı adlarının üçüncü şahıslar tarafından ele geçirilmesinden sorumlu tutulamayacağını kabul ve taahhüt eder. <br />
                                e) Kullanıcı, Web sitesi üzerinden verilen hizmetlerden faydalanan diğer kullanıcılarının yazılımlarına, kişisel verilerine veya diğer bilgilerine izinsiz olarak ulaşmamayı ve/veya bunları kullanmamayı, üçüncü kişi ve kuruluşların kullanımına sunmamayı kabul eder. <br />
                                f) Kullanıcı, kanunen yasak bilgiler içeren mesajlar, zincir posta, virüs, solucan vs. gibi zararlı yazılımlar ile üçüncü şahıslara zarar verebilecek başkaca herhangi bir içeriği dağıtmayacağını; diğer kullanıcıların bilgisayarındaki bilgilere ya da yazılıma zarar verecek program ve/ya bilgiler göndermemeyi veya bir yöntem kullanmamayı kabul, beyan ve taahhüt eder. <br />
                                g) Kullanıcı, Furkan Kurt (localination) web sitesinde sunulan hizmetlerden faydalandığı sürece Fikir ve Sanat Eserleri Yasası, Sınai Mülkiyet Kanunu, Türk Ticaret Kanunu, Türk Ceza Kanunu, Türk Borçlar Kanunu ve diğer mevzuat hükümleri ile Furkan Kurt (localination) tarafından yayınlanan bildirimlere riayet etmeyi ve bunları ihlal etmemeyi kabul, beyan ve taahhüt eder. <br />
                                h) Furkan Kurt (localination)’a ait web sitesindeki Furkan Kurt (localination) logosu ile sitenin içeriği ve yazılımların her türlü fikri ve mali hakkı Furkan Kurt (localination)’a aittir. Kullanıcı, web sitesinin içindekileri izlemek ve/veya kopyalamak, çoğaltmak, değiştirmek amacıyla ya da izin ve yetki verilmeyen başka amaçlarla herhangi bir yöntem kullanmayacağını; bu içerikten türev eserler yaratmayacağını ya da bunları kamuya teşhir etmeyeceğini kabul, beyan ve taahhüt eder. <br />
                                ı) Kullanıcı, Furkan Kurt (localination)’ın site aracılığıyla kullanıcılar tarafından sağlanan grafik, yazı, resim, görsel, metin ve benzeri içeriklere yer verebileceğini bildiğini ve bu nedenle diğer Kullanıcılar tarafından sağlanan her türlü içerikten içeriği yayınlayan Kullanıcının sorumlu olduğunu ve Furkan Kurt (localination)’ın hiçbir şekilde sorumlu olmadığını ve kendisi tarafından bir içerik yayınlanması durumunda, bu içeriğin Furkan Kurt (localination)’ın yayın ilkelerine ve yasalara aykırı olması veya Furkan Kurt (localination) tarafından bu şekilde değerlendirilmesi durumunda ilgili içeriği yayından kaldırma hakkına sahip olduğunu, Kullanıcı olarak içeriğin kaldırılmasına itiraz hakkının bulunmadığını kabul, beyan ve taahhüt eder. <br />
                                i) Kullanıcı, kendisi ile ilgili vereceği bilgilerin doğru olduğunu, verdiği bilgilerin değişmesi durumunda ilk fırsatta ve kanunen bir süre öngörüşmüş ise süresi içerisinde bu bilgileri güncelleyeceğini, kendisi tarafından yayınlanacak tüm görüş, düşünce, ifade ve resimlerin Furkan Kurt (localination) ile hiçbir ilgi ve bağlantısı olmadığını ve bunların sadece kendisini bağlayacağını beyan, kabul ve garanti eder. <br />
                                j) Kullanıcı, web sitesini ticari tanıtım alanı olarak kullanamaz. Site aracılığıyla ürün veya hizmet tanıtımı ya da satışı yapma veya diğer kullanıcıları satış kanallarına yönlendirme girişiminde bulunamaz. Furkan Kurt (localination), kendi takdirine göre, web sitesini bu amaçlarla kullandığını tespit ettiği üyelerin üyeliklerine son verme ve/veya sayfalarını yayından kaldırma hakkına sahiptir. <br />
                                k) Üye, üyelikten ayrılmaları veya Üyeliğin Furkan Kurt (localination) tarafından sonlandırılması durumunda Furkan Kurt (localination)'dan herhangi bir hak talep edemez. <br />
                                l) Kullanıcı, ister üye olsun isterse sadece ziyaretçi, işbu maddedeki herhangi bir yükümlülüğünü ihlal etmesi nedeniyle; <br />
                                - Zarara uğraması durumunda, bunu Furkan Kurt (localination)’tan talep etme hakkı bulunmadığını, bu amaçla Furkan Kurt (localination)’a herhangi bir dava veya talep yönlendirmeyeceğini, <br />
                                - İhlali nedeniyle üçüncü bir kişinin zarara uğraması durumunda bu zararı ilk talep anında ve derhal gidereceğini, gidermediği takdirde Furkan Kurt (localination)’ın zararı ödediği tarihten itibaren tüm yasal faizleri ve diğer ferileri ile birlikte kendisine rücu edeceğini bildiğini, <br />
                                - İhlal nedeniyle Furkan Kurt (localination)’ın adli veya idari para cezası ödemesine neden olduğu takdirde, Furkan Kurt (localination)’ın bu miktarı fiili ödeme tarihinden itibaren tüm yasal faizleri ve diğer ferileri ile birlikte kendisine rücu edeceğini bildiğini, <br />
                                - İhlali nedeniyle Furkan Kurt (localination)’ın doğrudan bir zarara uğraması durumunda, zararın oluştuğu tarihten itibaren ilgili zararı tüm yasal faiz ve ferileri ile birlikte Furkan Kurt (localination)’a ödeyeceğini, kabul, beyan ve taahhüt eder. <br />
                                4. Web Sitesinin Yetkileri <br />
                                a) Furkan Kurt (localination) her zaman için tek taraflı olarak Kullanıcıya, üyeye verilen hizmeti sürekli veya geçici durdurabileceği gibi herhangi bir sayfasındaki ya da hizmetindeki kullanıcı erişimini neden belirtmeksizin ve uyarmaksızın, erteleme, sınırlama ve yok etme hakkına sahiptir. Kullanıcı bu nedenle Furkan Kurt (localination)’ı herhangi bir ad altında sorumlu tutamaz. <br />
                                b) Furkan Kurt (localination), Üyelik bilgilerini eksik veya hatalı bildiren Üyeler ile işbu üyelik sözleşmesi ile Web’de açıklanan sair kullanım koşullarına aykırı davranan Kullanıcıların, hiçbir açıklama yapmaksızın kullanımlarını, üyeliklerini sonlandırma veya dondurma hakkına sahiptir. Ayrıca Furkan Kurt (localination), belirli zaman dilimi boyunca güncellenmediğini tespit ettiği üyelerin hesaplarını da hiçbir açıklama yapmaksızın sonlandırma veya dondurma hakkına sahiptir. <br />
                                c) Furkan Kurt (localination), Web’de verdiği hizmetlerin kesintisiz veya hatasız olacağını ya da sitenin içeriğinin kullanılmasıyla ya da siteye bağlantı yapılmasıyla belirli sonuçların elde edileceğini garanti etmemektedir. Furkan Kurt (localination), kullanıcıların ve üyelerin, Site’den sunduğu hizmet ile ilgili her türlü güvenlik önlemini almış olsa da, bilişim sistemlerinin doğası gereği, Kullanıcılar tarafından siteye yüklenen herhangi bir uygulama veya dosya ya da benzeri bir içerik nedeniyle kullanıcının sisteminin zarar görmeyeceğini garanti etmemektedir. Furkan Kurt (localination), doğrudan, dolaylı veya arızi zararlar, netice kabilinden doğan zararlar ve cezai tazminatlar da dahil olmak üzere, Web’in kullanılmasından kaynaklanabilecek zararlar için sorumlu tutulamaz. <br />
                                d) Furkan Kurt (localination), Kullanıcılardan gelen içeriği, e-postayı ya da sayfalarında bulunan herhangi bir iletiyi neden göstermeden reddetme, geciktirme, geç yayına alma, yayınlamama veya yayından kaldırma hakkına sahiptir. <br />
                                e) Furkan Kurt (localination)’a ait sitede reklam ve tanıtım faaliyetleri yapma hakkı sadece Furkan Kurt (localination)'a veya Furkan Kurt (localination)’ın yetki verdiği üçüncü kişilere aittir. <br />
                                f) Furkan Kurt (localination), Web Sitesinde kendi markası olan veya olmayan diğer sitelere link verebilir. Furkan Kurt (localination)’ın, link verdiği ya da tavsiye ettiği sitelerin, içerikleri zaman içerisinde değişebileceği de gözetildiğinde, dış sitelere verilen bağlantılar veya dış siteler ile ilgili yapılan tavsiyeler nedeniyle bu sitelere gerçekleşecek ziyaretlerde, kullanıcıların bilgi kullanımı, ilgili sitelerin gizlilik ilkeleri ve içeriğinden tamamen dış site sorumlu olup, Furkan Kurt (localination) sorumlu değildir. <br />
                                g) Web Sitesi’ndeki kullanıcı isimlerinin, e-postalarının ve diğer kişisel verilerin izinsiz ve yetkisiz kişiler tarafından herhangi bir nedenle toplanması ilgili mevzuat gereği suç teşkil etmektedir. <br />
                                h) Furkan Kurt (localination), Web Sitesi’nden yapılan yayınların içeriği 18 yaş altındaki çocuklar için uygun olmayabilir. Furkan Kurt (localination) bilerek 18 yaş altındaki çocuklardan bilgi talep etmez. Eğer herhangi bir şekilde 18 yaşın altındaki bir kullanıcının Furkan Kurt (localination) ile kişisel bilgi paylaştığını fark edersek, bu bilgileri Furkan Kurt (localination) sistemlerinden sileriz. Eğer bir ebeveyn çocuğunun Furkan Kurt (localination) Platformlarında kendi rızası olmadan kişisel bilgilerini paylaştığını fark eder ise bu kişisel bilgileri Bizimle iletişime geçerek silinmesini talep edebilir. Yasal nedenlerle oluşacak kayıtlar haricinde kayıtlar sistemlerden derhal silinir. <br />
                                ı) Furkan Kurt (localination) teknik nedenlerden kaynaklanan fiyat, ürün ve her türlü güncelleme hatalarından sorumlu olmayıp, dilediği zaman sitede yer alan ve ilan edilen fiyatları güncelleyebilir, değiştirebilir. <br />
                                5. Gizlilik Ve Kişisel Verilerin İşlenmesi <br />
                                a) Size daha iyi hizmet verebilmek, ihtiyaçlarınızı ve ilgi alanlarınızı anlayabilmek için, Furkan Kurt (localination) uygun bildirim yaparak ve onay alarak kişisel bilgileri toplar, iletir ve kullanır. Web sitesini kullanmanız ve sadece kullanıcı olarak kalmanız veya üyeliğinizi tamamlayarak Üye olmanız halinde aşağıdaki şart ve koşulları kabul, beyan ve taahhüt etmektesiniz. <br />
                                Furkan Kurt (localination), verdiğiniz bilgilere ek olarak, bir Furkan Kurt (localination) sitesini ziyaret ettiğinizde, Web sinyalleri, tanımlama bilgileri, karıştırılmış Web bağlantıları ve sıkça kullanılan diğer bilgi toplama araçları gibi Otomatik Veri Toplama Araçları ile de bilgi toplayabilir. Bu araçlar, tarayıcınızın web sitemize gönderdiği, tarayıcı türünüz ve dili, erişim zamanları ve Furkan Kurt (localination) web sitelerinden birine geçiş yaptığınız web sitesinin adresi gibi belirli standart bilgileri toplar. Ayrıca, bu bilgiler İnternet protokol (IP) adresiniz, tıklatma akışı davranışınız (yani görüntülediğiniz sayfalar, tıklattığınız bağlantılar ve Furkan Kurt (localination) web siteleriyle bağlantılı olarak yaptığınız diğer eylemler) ve ürün bilgilerini de içerebilir. IP adresi, Web'de her gezindiğinizde bilgisayarınıza otomatik olarak atanan ve Web sunucularının bilgisayarınızın yerini belirlemesini ve tanımlamasını sağlayan bir numaradır. Bilgisayarlar IP adreslerini İnternet'te iletişim kurmak ve kullanıcıların tarama ve alışveriş yapmalarını sağlamak üzere kullanırlar. Furkan Kurt (localination) ayrıca bu Otomatik Veri Toplama Araçlarının bazılarını gönderdiği bazı e-postalarında da kullanabilir. Böylece bir e-postayı açtığınızda ya da e-postada bulunan bir bağlantıyı tıklattığınızda da bilgi toplayabilir.
                                Furkan Kurt (localination) ayrıca güvenilir olduğuna inandığı, ticari amaçlı kaynaklardan da bilgi toplar. Adınız, adresiniz, e-posta adresiniz ve demografik bilgileriniz bu tür bilgiler arasında yer alabilir. Furkan Kurt (localination)'ın ticari kaynaklardan topladığı bilgiler, Furkan Kurt (localination) web sitelerini ziyaret ettiğinizde Furkan Kurt (localination)'ın topladığı bilgilerle birlikte kullanılabilir. Örneğin, Furkan Kurt (localination) genel coğrafi konumunuzu belirlemek üzere, ticari kaynaklardan edindiği coğrafi bilgileri, Otomatik Veri Toplama Araçları'yla topladığı IP adresleriyle karşılaştırabilir.
                                Eğer sadece ziyaretçi olarak kalır ve sayfalarımızı üye olmadan ziyaret ederseniz, sizinle ilgili yasal zorunluluklar haricinde bir bilgi toplamayız.
                                Eğer, çerezlere (cookie) izin verirseniz (bu izinler sizin bilgisayarınızda/mobil cihazınızda kullandığınız tarayıcı programındaki ayarlarda sizin tarafınızdan yönetilebilmektedir) sizinle ilgili bazı ek bilgilere erişmemiz söz konusu olur.
                                Eğer, ayrıca Siteye Üye olursanız, Size eksiksiz şekilde hizmet sunmaya çalışabiliriz. Üye’liğinizi kayıt altına almak ve sizi diğer Üyelerden ayırmak adına; isim, soy isim, doğum tarihi, medeni durum, yaşanılan şehir ve semt, eğitim durumu, cinsiyet ve sizinle iletişim kurmak için; cep ve iş telefon numaraları ve elektronik posta bilgileriniz veya üyesi olduğunuz sosyal medya platformu üzerinden sağlanan bilgileriniz tarafımızdan toplanabilir. Hizmetlerimiz kapsamında elektronik cihazlar üzerinden alınan konum verileri de işlenebilir. İlerleyen dönemlerde Üye’lere olan faydalarını artırmak amacıyla, ilgi alanları, aile ile ilgili bilgiler gibi ek bilgileriniz de alınabilecektir.
                                Üye olmak için doldurduğunuz formda yer alan kişisel verileriniz; siparişlerinizi almak, ürün ve hizmetlerimizi sunmak, ödemelerinizi gerçekleştirmek, siparişleriniz, ürünler ve hizmetler hakkında pazarlama amacıyla sizinle irtibata geçmek, ilginizi çekebilecek ürün ve hizmetleri önermek, marka işbirlikleriyle ilgili kampanya ve promosyon duyurularını iletmek, üçüncü kişilerin teknik, lojistik ve benzeri diğer işlevlerini bizim adımıza yerine getirmelerini sağlamak, elektronik posta ile bülten göndermek ya da bildirimlerde bulunmak, sorularınızı cevaplamak ve etkin bir müşteri hizmeti sunmak, yeni içerikler hakkında bilgi vermek gibi amaçlar ile otomatik olan/olmayan yöntemler ile temini, devralınması, yurt içinde ve dışında yazılı/manyetik arşivlere kaydedilmesi, depolanması, muhafazası, elde edilebilir hale getirilmesi, kullanılması, güncellenmesi, değiştirilmesi, birleştirilmesi, yeniden düzenlenmesi, sınıflandırılması, açıklanması, paylaşımı, yurt içine veya yurt dışına aktarılması, transferi ve sair suretler ile işlenecek olup işbu program dışında kalan hiçbir amaç için kullanılmayacaktır.
                                Üyeler, program kapsamında kişisel bilgilerin değişmesi halinde gerekli güncellemeleri kendileri yapabilir veya bu güncellemelerin yapılmasını talep edebilir. Furkan Kurt (localination), Üye ile SMS/kısa mesaj, anlık bildirim, otomatik arama, bilgisayar, telefon, faks, elektronik posta ve diğer elektronik iletiler ve diğer gönderim şekilleri ile iletişime geçebilir. İşbu Üye’lik dahilinde kampanya, elektronik bülten ve sair yollar ile elektronik ileti gönderilmesine onay vermeniz halinde, dilediğiniz zaman bu onayınızı iletilerde yer alan yönlendirmeler doğrultusunda veya www.localination.com./contact/ sayfasında yer alan iletişim bilgileri aracılığı ile Furkan Kurt (localination) ile irtibata geçerek ayrılma isteğinizi ileterek listeden çıkabilirsiniz. Furkan Kurt (localination) bu şekilde gönderim listesinden çıkışını sağlayacaktır.
                                Kişisel verileriniz, Üye’liğiniz kapsamında elektronik bülten gönderilmesi, reklam ve pazarlama faaliyetleri, şikayetlerinizin değerlendirilmesi ve Üye’liğinizin etkin bir şekilde işlemesi için Furkan Kurt (localination)’ın program ortakları, hizmet sağlayıcıları, tedarikçileri ile Furkan Kurt (localination) iştirakleri ve bunların belirleyeceği üçüncü kişiler/kuruluşlar da dahil olmak üzere yurt içi ve yurt dışındaki üçüncü kişilere aktarılabilir. Üyeliğiniz kapsamında toplanan kişisel veriler, Üyeliğin Üye’nin kendisi veya Furkan Kurt (localination) tarafından sonlandırılmasının ardından, ilgili mevzuat tarafından öngörülen süreler saklı kalmak kaydıyla Üye’nin talebi veya Furkan Kurt (localination) tarafından re’sen silinir, yok edilir veya anonimleştirilir.
                                Üye, üyeliğini dilediği zaman sonlandırma hakkına haizdir. Üyelik, üçüncü kişilere devredilemez veya satılamaz.
                                Furkan Kurt (localination) tarafından işlenen kişisel verileriniz 6698 sy. Kişisel Verilerin Korunması Kanunu’na uygun şekilde tarafımızca idari ve teknik her türlü güvenlik önlemi alınarak, yetkisiz erişime izin verilmeden güvenli şekilde saklanacaktır.
                                Üye’nin kişisel verileri üzerindeki diğer hakları ve kişisel verileri işleme ilke ve yöntemleri ile verilerle ilgili alınan idari ve teknik güvenlik önlemleri hakkındaki detaylı bilgilere www.localination.com  adresli internet sitemizden inceleyebilirsiniz. <br />
                                6. Fesih ve Değişiklikler <br />
                                Furkan Kurt (localination) ve Kullanıcı bu sözleşmeyi dilediği zaman sona erdirebilir. <br />
                                Furkan Kurt (localination) bu üyelik sözleşmesinin koşullarında, web sitesinde sunduğu hizmetlerde dilediği zaman ve yasal düzenlemeler gereği de tek taraflı olarak değişiklik, ayarlama ve güncelleme yapma hakkına sahiptir. Bu değişiklikler, ayarlamalar ve güncellemeler Web’de yayınlandığı andan itibaren geçerlilik kazanmış sayılır. Kullanıcılar bu Web Sitesi’ne her yeni girişlerinde, kullanımlarında güncellenmiş/değiştirilmiş/ayarlanmış maddeleri kabul etmiş sayılmaktadırlar. <br />
                                Kullanıcı, üye; Furkan Kurt (localination) tarafından işbu sözleşme hükümleri uyarınca verilecek hizmeti kullanmaya başladığı tarihten itibaren, sözleşmede belirtilen hak ve yükümlülüklere aykırı hareketleri ve/veya edim ve taahhütlerini yerine getirmemesi nedeniyle, Furkan Kurt (localination)'ın ve/veya üçüncü kişilerin uğrayacağı her türlü zarar ve ziyanı derhal tazmin etmeyi ve Furkan Kurt (localination) tarafından üyenin üyeliğinin iptal ederek sözleşmeyi tek taraflı olarak feshedebileceğini kabul ve taahhüt eder. <br />
                                7. İhtilaf <br />
                                İşbu sözleşme kanunlar ihtilâfı kurallarına bakılmaksızın Türkiye Cumhuriyeti kanunlarına tâbi olup, bu kanunlara göre çözümlenecek ve Bursa Mahkemeleri görev ve yer olarak yetkili olacaktır. <br />
                                8. Tebligat Adresleri <br />
                                Kullanıcı veya Üyenin Furkan Kurt (localination)'e bildirdiği adres, bu sözleşme ile ilgili olarak yapılacak her türlü bildirim için yasal tebligat adresi kabul edilir. <br />
                                Kullanıcı veya Üye, mevcut adreslerindeki değişiklikleri yazılı olarak Furkan Kurt (localination)'a 3 (üç) gün içinde bildirmedikçe, eski adreslere yapılacak bildirimlerin geçerli olacağını ve kendisine yapılmış sayılacağını kabul eder. <br />
                                İşbu sözleşme nedeniyle Furkan Kurt (localination)’ın üyenin sistemde kayıtlı e-posta adresini kullanarak yapacağı her türlü bildirim aynen geçerli olup, e-postanın Furkan Kurt (localination) tarafından yollanmasından 1 (bir) gün sonra üyeye ulaştığı kabul edilecektir. <br />
                                9. Yürürlük <br />
                                Kullanıcı veya Üye, bu sözleşmede yer alan maddelerin tümünü okuduğunu, anladığını, kabul ettiğini ve kendisiyle ilgili olarak verdiği bilgilerin doğruluğunu onayladığını beyan, kabul ve taahhüt eder. İşbu sözleşme, Kullanıcı veya üyenin Web sitesine girmesi veya üye olması anında akdedilmiş ve karşılıklı ve süresiz olarak yürürlüğe girmiştir. Sözleşme üyeliğin sona ermesi ile veya işbu sözleşmede sayılan fesih hallerinden herhangi birinin gerçekleşmesi ile hiçbir ihtara gerek kalmaksızın kendiliğinden hükümsüz kalacaktır.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
