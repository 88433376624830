import { FC, useEffect, useState } from "react"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useListView } from "../settings-list/core/ListViewProvider"
import { Setting } from "../settings-list/core/_models"

type Props = {
  isUserLoading: boolean
  user: Setting
}

const SettingsModalHeader: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const [data, setData] = useState<any>()
  useEffect(() => {
    setData(user)
  }, [])
  return (
    <div>
      {/* begin::Modal title */}
      {data?.id !== undefined ?
        <>
          <div className='modal-header' >
            <h2 className='fw-bolder'>{data?.title}</h2>
            <div
              className='btn btn-icon btn-sm btn-active-icon-primary'
              data-kt-users-modal-action='close'
              onClick={() => setItemIdForUpdate(undefined)}
              style={{ cursor: 'pointer' }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div></div>
        </>
        :
        <>
          <div className='modal-header'>

            <h2 className='fw-bolder'>Pop-Up Ekle</h2>
            <div
              className='btn btn-icon btn-sm btn-active-icon-primary'
              data-kt-users-modal-action='close'
              onClick={() => setItemIdForUpdate(undefined)}
              style={{ cursor: 'pointer' }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
        </>}
    </div>

  )
}

export { SettingsModalHeader }
