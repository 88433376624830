import {useQuery} from 'react-query'
import { useListView } from '../project-list/core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { getProjectDetails } from '../project-list/core/_requests'
import { ProjectEditModalForm } from './ProjectEditModalForm'


const ProjectEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.BLOG_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getProjectDetails(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ProjectEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }

  if (!isLoading && !error && user) {
    return <ProjectEditModalForm isUserLoading={isLoading} user={user} />
  }

  return null
}

export {ProjectEditModalFormWrapper}
