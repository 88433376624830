import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Project, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_PROJECT_URL = `${API_URL}contentTransactions/project/List`
const GET_PROJECT_DETAIL_URL = `${API_URL}contentTransactions/project/Detail`
const ADD_PROJECT_URL = `${API_URL}contentTransactions/project/Add`
const EDİT_PROJECT_URL = `${API_URL}contentTransactions/project/Edit`
const DELETE_PROJECT_URL = `${API_URL}contentTransactions/project/Delete`


const getProject = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_PROJECT_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getProjectDetails = (id: ID): Promise<Project | undefined> => {
  const all = axios
    .post(`${GET_PROJECT_DETAIL_URL}/${id}`)
    .then((response: Response<Project>) => response.data)
    return all;
}

const addProject = (user: Project): Promise<Project | undefined> => {
  const all = axios
  .post(ADD_PROJECT_URL, user)
  .then((response: Response<Project>) => response.data)
  return all
}

const editProject = (user: Project): Promise<Project | undefined> => {
  return axios
    .post(`${EDİT_PROJECT_URL}/${user.id}`, user)
    .then((response: Response<Project>) => response.data)
}

const deleteProject= (userId: ID): Promise<void> => {
  return axios.delete(`${DELETE_PROJECT_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${DELETE_PROJECT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}



export {deleteProject, getProject, deleteSelectedUsers, getProjectDetails, addProject, editProject}
