import { FC, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { TagsInput } from "react-tag-input-component";
import clsx from 'clsx'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ReferenceListLoading } from '../loading/ReferenceListLoading'
import { addReference, editReference } from '../reference-list/core/_requests';
import { Reference, initialUser } from '../reference-list/core/_models';
import { useListView } from '../reference-list/core/ListViewProvider';
import { useQueryResponse } from '../reference-list/core/QueryResponseProvider';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ link: 'link' }],
  ]
}
type Props = {
  isUserLoading: boolean
  user: Reference
}
const editUserSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Başlık zorunludur'),
  substructure: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Alt Yapı zorunludur'),
  category
    : Yup.string()
      .min(3, 'Minimum 3 karakter')
      .max(50, 'Maksimum 50 karakter')
      .required('Kategori zorunludur'),
  site: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maksimum 50 karakter')
    .required('Web Site zorunludur'),
  list_text
    : Yup.string()
      .min(3, 'Minimum 3 karakter')
      .required('Liste Yazısı zorunludur'),
  summary_text: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .required('Özet Yazısı zorunludur'),
  problem_content: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .required('Problem İçeriği zorunludur'),
  solution_content: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .required('Çözüm İçeriği zorunludur'),
  result_content: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .required('Sonuç İçeriği zorunludur'),
})
const ReferenceEditModalForm: FC<Props> = ({ user, isUserLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [value, setValue] = useState('');
  const [valueSummary, setValueSummary] = useState('');
  const [solutionContent, setSolutionContent] = useState('');
  const [problemContent, setProblemContent] = useState('');
  const [resultContent, setResultContent] = useState('');
  const [logos, setLogos] = useState<unknown>("" || undefined);
  const [galery, setGalery] = useState<any>([] || undefined)
  const [contentlogo, setContentLogo] = useState<unknown>("" || undefined);
  const [images, setImages] = useState<any>([]);
  const [size, setSize] = useState<any>()
  const [selected, setSelected] = useState(["içerik"]);
  const [userForEdit] = useState<Reference>({
    ...user,
    images: user.images || initialUser.images,
    title: user.title || initialUser.title,
    substructure: user.substructure || initialUser.substructure,
    category: user.category || initialUser.category,
    site: user.site || initialUser.site,
    image: user.image || initialUser.image,
    logo: user.logo || initialUser.logo,
    list_text: user.list_text || initialUser.list_text,
    summary_text: user.summary_text || initialUser.summary_text,
    problem_content: user.problem_content || initialUser.problem_content,
    solution_content: user.solution_content || initialUser.solution_content,
    result_content: user.result_content || initialUser.result_content,

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values = { ...values, image: String(logos), logo: String(contentlogo), images: galery }
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await editReference(values).then((response) => {
          })
        } else {
          await addReference(values).then((response) => {
          })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  const handleCreateBase64 = useCallback(async (e: any) => {
    const file = e.target.files[0];
    console.log("file", file)
    const name = e.target.name
    const base64 = await convertToBase64(file);

    if (name === "image") {
      formik.values.image = `${base64}`
      setLogos(base64);
    } else if (name === "images") {
      setGalery((prevState: any) => {
        const existingIndex = prevState.findIndex((item: any) => base64 === item);
        if (existingIndex === -1) {
          console.log("images", prevState)
          return [...prevState, base64];
        } else {
          return prevState;
        }
      });
    }
    else {
      formik.values.logo = `${base64}`
      setContentLogo(base64)
    }

    e.target.value = "";
  }, [])
  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) {
        alert('select an image')
      }
      else {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  useEffect(() => {
    console.log("galrey,", galery)
    if (!isNotEmpty(formik.values.id)) {
      formik.setFieldValue("images", galery)
    } else {
      if (galery?.length > 0) {
        formik.setFieldValue("images", galery)
      }
    }
  }, [galery])

  useEffect(() => {
    setLogos(`${process.env.REACT_APP_MEDIA_API_URL}references/` + user?.image)
  }, [])

  useEffect(() => {
    setContentLogo(`${process.env.REACT_APP_MEDIA_API_URL}references/` + user?.logo)
  }, [])




  const deleteImage = (e: any) => {
    e.preventDefault();
    setLogos(null);
  }
  const deleteGaleries = (e: any, index: number, picture: any) => {
    console.log("index", index)
    setImages((prevState: any) => prevState.filter((not: any) => not !== picture));
    e.preventDefault();
  }

  const deleteGalery = (e: any) => {
    e.preventDefault();
  }
  const deleteContentImage = (e: any) => {
    e.preventDefault();
    setContentLogo(null);
  }

  useEffect(() => {
    formik.values.images?.map((image: any) => {
      if (image.image === undefined) {
        setImages((prevState: any) => {
          const existingIndex = prevState.findIndex((item: any) => image === item);
          if (existingIndex === -1) {
            return [...prevState, image];
          } else {
            return prevState;
          }
        })
      } else {
        setImages((prevState: any) => [...prevState, image.image])
      }
    })
    console.log("formik.values.images:", formik.values.images)
    setSize(formik.values.images?.length)
  }, [formik.values.images])
  useEffect(() => {
    images?.map((as: any) => {
      console.log("as", as)
      let result = as.includes("content_image");
      console.log("result:", result)
    })
    console.log("images", images)
  }, [images])

  useEffect(() => {
    if (user.id !== undefined) {
      setValue(String(user.list_text || initialUser.list_text));
      setValueSummary(String(user.summary_text || initialUser.summary_text));
      setProblemContent(String(user.problem_content || initialUser.problem_content));
      setSolutionContent(String(user.solution_content || initialUser.solution_content));
      setResultContent(String(user.result_content || initialUser.result_content));
    } else {
    }

  }, [user])
  useEffect(() => { formik.setFieldValue("list_text", value) }, [value])
  useEffect(() => { formik.setFieldValue("summary_text", valueSummary) }, [valueSummary])
  useEffect(() => { formik.setFieldValue("problem_content", problemContent) }, [problemContent])
  useEffect(() => { formik.setFieldValue("solution_content", solutionContent) }, [solutionContent])
  useEffect(() => { formik.setFieldValue("result_content", resultContent) }, [resultContent])
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Başlık</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Başlık Giriniz'
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.title && formik.errors.title },
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.title}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Alt Yapı</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Alt Yapı giriniz'
              {...formik.getFieldProps('substructure')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.substructure && formik.errors.substructure },
                {
                  'is-valid': formik.touched.substructure && !formik.errors.substructure,
                }
              )}
              type='text'
              name='substructure'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.substructure && formik.errors.substructure && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.substructure}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fs-6 mb-5 required fw-bold '>Galeri</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {size > 0 ?
                <>
                  {isNotEmpty(formik.values.id) ?
                    <>
                      {images?.map((picture: any, index: number) => {
                        let result = picture.includes("content_image");
                        return (
                          <div key={index}>
                            {result ?
                              <>
                                <div
                                  className='image-input-wrapper w-125px h-125px'
                                  style={{ backgroundImage: `url('${process.env.REACT_APP_MEDIA_API_URL}references/${picture}')` }}
                                >
                                  {/* {logos ? (<img src="logos" alt="" />) : null} */}
                                </div>
                              </>
                              :
                              <>
                                <div
                                  className='image-input-wrapper w-125px h-125px'
                                  style={{ backgroundImage: `url('${picture}')` }}
                                >
                                  {/* {logos ? (<img src="logos" alt="" />) : null} */}
                                </div>
                              </>
                            }
                            <label
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='change'
                              data-bs-toggle='tooltip'
                              title='Change avatar'
                            >
                              <i className='bi bi-pencil-fill fs-7'></i>

                              <input
                                type='file'
                                {...formik.getFieldProps('images')}
                                value={undefined}
                                onChange={handleCreateBase64}
                                accept='.png, .jpg, .jpeg, .webp, .svg'
                              />
                              <input type='hidden' onClick={(e) => { deleteGaleries(e, index, picture) }} name='avatar_remove' />
                            </label>
                            {/* end::Label */}

                            {/* begin::Cancel */}
                            <span
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='cancel'
                              data-bs-toggle='tooltip'
                              title='Cancel avatar'
                            >
                              <i className='bi bi-x fs-2'></i>
                            </span>
                            <span
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='remove'
                              data-bs-toggle='tooltip'
                              title='Remove avatar'
                              onClick={(e) => { deleteGaleries(e, index, picture) }}
                            >
                              <i className='bi bi-x fs-2'></i>
                            </span>
                          </div>
                        )
                      })}
                    </>
                    :
                    <>
                      {formik.values.images?.map((picture: any, index: number) => {
                        return (
                          <div key={index}>
                            <div
                              className='image-input-wrapper w-125px h-125px'
                              style={{ backgroundImage: `url('${picture}')` }}
                            >
                              {/* {logos ? (<img src="logos" alt="" />) : null} */}
                            </div>
                            <label
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='change'
                              data-bs-toggle='tooltip'
                              title='Change avatar'
                            >
                              <i className='bi bi-pencil-fill fs-7'></i>

                              <input
                                type='file'
                                {...formik.getFieldProps('images')}
                                value={undefined}
                                onChange={handleCreateBase64}
                                accept='.png, .jpg, .jpeg, .webp, .svg'
                              />
                              <input type='hidden' onClick={(e) => { deleteGaleries(e, index, picture) }} name='avatar_remove' />
                            </label>
                            {/* end::Label */}

                            {/* begin::Cancel */}
                            <span
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='cancel'
                              data-bs-toggle='tooltip'
                              title='Cancel avatar'
                            >
                              <i className='bi bi-x fs-2'></i>
                            </span>
                            <span
                              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='remove'
                              data-bs-toggle='tooltip'
                              title='Remove avatar'
                              onClick={(e) => { deleteGaleries(e, index, picture) }}
                            >
                              <i className='bi bi-x fs-2'></i>
                            </span>
                          </div>
                        )
                      })}
                    </>
                  }
                </>
                :
                <>
                  <div>
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{ backgroundImage: `url('${galery}')` }}
                    >
                      {/* {logos ? (<img src="logos" alt="" />) : null} */}
                    </div>
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      title='Change avatar'
                    >
                      <i className='bi bi-pencil-fill fs-7'></i>

                      <input
                        type='file'
                        {...formik.getFieldProps('images')}
                        value={undefined}
                        onChange={handleCreateBase64}
                        accept='.png, .jpg, .jpeg, .webp, .svg'
                      />
                      <input type='hidden' onClick={deleteGalery} name='avatar_remove' />
                    </label>
                    {/* end::Label */}

                    {/* begin::Cancel */}
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel avatar'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      title='Remove avatar'
                      onClick={deleteGalery}
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                  </div>
                </>}

            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'>İzin verilen formatlar: png, jpg, jpeg.</div>
            {/* end::Hint */}
            {formik.touched.images && formik.errors.images && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.images}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fs-6 mb-5 required fw-bold '>Resim</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${logos}')` }}
              >
                {/* {logos ? (<img src="logos" alt="" />) : null} */}
              </div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input
                  type='file'
                  {...formik.getFieldProps('image')}
                  value={undefined}
                  onChange={handleCreateBase64}
                  accept='.png, .jpg, .jpeg, .webp, .svg'
                />
                <input type='hidden' onClick={deleteImage} name='avatar_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={deleteImage}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'>İzin verilen formatlar: png, jpg, jpeg.</div>
            {/* end::Hint */}
            {formik.touched.image && formik.errors.image && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.image}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fs-6 mb-5 required fw-bold '>Logo</label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${blankImg}')` }}
            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${contentlogo}')` }}
              >
                {/* {logos ? (<img src="logos" alt="" />) : null} */}
              </div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input

                  type='file'
                  {...formik.getFieldProps('logo')}
                  value={undefined}
                  onChange={handleCreateBase64}
                  accept='.png, .jpg, .jpeg, .webp, .svg'


                />
                <input type='hidden' onClick={deleteContentImage} name='avatar_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={deleteContentImage}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'>İzin verilen formatlar: png, jpg, jpeg.</div>
            {/* end::Hint */}
            {formik.touched.logo && formik.errors.logo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.logo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Kategori</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Kategori giriniz'
              {...formik.getFieldProps('category')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.category && formik.errors.category },
                {
                  'is-valid': formik.touched.category && !formik.errors.category,
                }
              )}
              type='text'
              name='category'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.category && formik.errors.category && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.category}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Web Site</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Web Site giriniz'
              {...formik.getFieldProps('site')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.site && formik.errors.site },
                {
                  'is-valid': formik.touched.site && !formik.errors.site,
                }
              )}
              type='text'
              name='site'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.site && formik.errors.site && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.site}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Liste Yazısı</label>
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.list_text && formik.errors.list_text,
                },
                {
                  'is-valid': formik.touched.list_text && !formik.errors.list_text
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.list_text && formik.errors.list_text && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.list_text}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Özet Yazı</label>
            <ReactQuill
              theme="snow"
              value={valueSummary}
              onChange={setValueSummary}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.summary_text && formik.errors.summary_text,
                },
                {
                  'is-valid': formik.touched.summary_text && !formik.errors.summary_text
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.summary_text && formik.errors.summary_text && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.summary_text}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Problem İçeriği</label>
            <ReactQuill
              theme="snow"
              value={problemContent}
              onChange={setProblemContent}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.problem_content && formik.errors.problem_content,
                },
                {
                  'is-valid': formik.touched.problem_content && !formik.errors.problem_content
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.problem_content && formik.errors.problem_content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.problem_content}</span>
                </div>
              </div>
            )}
          </div>


          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Çözüm İçeriği</label>
            <ReactQuill
              theme="snow"
              value={solutionContent}
              onChange={setSolutionContent}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.solution_content && formik.errors.solution_content,
                },
                {
                  'is-valid': formik.touched.solution_content && !formik.errors.solution_content
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.solution_content && formik.errors.solution_content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.solution_content}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Sonuç İçeriği</label>
            <ReactQuill
              theme="snow"
              value={resultContent}
              onChange={setResultContent}
              modules={modules}
              className={clsx(
                "form-control ",
                {
                  'is-invalid': formik.touched.result_content && formik.errors.result_content,
                },
                {
                  'is-valid': formik.touched.result_content && !formik.errors.result_content
                }
              )}
              placeholder="Metin Giriniz"
            />
            {formik.touched.result_content && formik.errors.result_content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.result_content}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            İptal
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{isNotEmpty(formik.values.id) ? 'Güncelle' : 'Kaydet'}</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Lütfen Bekleyin...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ReferenceListLoading />}
    </>
  )
}

export { ReferenceEditModalForm }
