import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const LİST_COUNTRY_URL = `${API_URL}GuestController/listCountry`
const LİST_TOWN_URL = `${API_URL}GuestController/listTown`
const LİST_CİTY_URL = `${API_URL}GuestController/listCity`
const LİST_ADDRESSES_URL = `${API_URL}addressTransactions/listAdresses`
const ADD_ADDRESSES_URL = `${API_URL}addressTransactions/addAdresses`
const EDİT_ADDRESSES_URL = `${API_URL}addressTransactions/editAdresses`
const CHECK_ADDRESSES_URL = `${API_URL}addressTransactions/checkAdresses`
const GET_ADDRESSES = `${API_URL}addressTransactions/getAdresses`


export function GetCountries() {
  return axios.get(LİST_COUNTRY_URL)
}

export function GetTowns(city_id:any) {
    return axios.get(LİST_TOWN_URL+'/'+city_id)
}

export function GetCities(country_id=223) {
    return axios.get(LİST_CİTY_URL+'/'+country_id)
}

export function ListAddresses(id:any) {
    return axios.get(GET_ADDRESSES+'/'+id)
}

export function  GetAddresses() {
    const address = axios.get(LİST_ADDRESSES_URL)
    return address
}
// Server should return AuthModel

export function AddAddress(values:any) {
    return axios.post(ADD_ADDRESSES_URL, values)
}

export function EditAddress(values:any){
    return axios.post(EDİT_ADDRESSES_URL, values)
}

export function CheckAddress(Address:any){
    return axios.post(CHECK_ADDRESSES_URL,Address)
}



