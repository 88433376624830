import React from 'react'
import { BlogListSearchComponent } from './BlogListSearchComponent'
import { BlogListGrouping } from './BlogListGrouping'
import { BlogListToolbar } from './BlogListToolbar'
import { useListView } from '../core/ListViewProvider'

export const BlogListHeader = () => {
    const { selected } = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            <BlogListSearchComponent />
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {selected.length > 0 ? <BlogListGrouping /> : <BlogListToolbar />}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}
