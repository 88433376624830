import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { CreditCard } from '../modules/apps/user-management/offer-one/CreditCard'
import { Blog } from '../modules/apps/blog/Blog'
import { Proje } from '../modules/apps/project/Proje'
import { Referans } from '../modules/apps/reference/Referans'
import { Ayarlar } from '../modules/apps/settings/Ayarlar'
import { PageTitle } from '../../_metronic/layout/core'
import { useAuth } from '../modules/auth'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const { currentUser, tokenPage } = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {currentUser?.authority === 1 ?
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          </>
          :
          <>
            {tokenPage ?
              <>
                <Route path='auth/*' element={<Navigate to={tokenPage} />} />
              </> :
              <>
                <Route path='auth/*' element={<Navigate to='/apps/user-management/offers' />} />
              </>}

          </>
        }
        {/* Pages */}
        {currentUser?.authority === 1 ? <>
          <Route path='/dashboard' element={<DashboardWrapper />} />
          <Route path="/blog" element={<><Blog /> <PageTitle >Bloglar</PageTitle> </>} />
          <Route path="/proje" element={<><Proje /> <PageTitle >Projeler</PageTitle></>} />
          <Route path="/referans" element={<><Referans /> <PageTitle ></PageTitle></>} />
          <Route path='/ayarlar' element={<><Ayarlar /> <PageTitle >Ayarlar</PageTitle></>} />
        </> : <></>}
        <Route path="/creditcard" element={<CreditCard />} />
        
       
        
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='auth' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
