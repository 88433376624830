import {useQuery} from 'react-query'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'
import { useListView } from '../settings-list/core/ListViewProvider'
import { getPopUpDetails } from '../settings-list/core/_requests'
import { SettingsEditModalForm } from './SettingsEditModalForm'
import { PopUpLook } from './PopUpLook'
import { useEffect } from 'react'


const SettingsEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate,updateOrreview} = useListView() 
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.BLOG_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getPopUpDetails(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!itemIdForUpdate) {
    return <SettingsEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }

  if (!isLoading && !error && user) {
    if(updateOrreview){
      return <SettingsEditModalForm isUserLoading={isLoading} user={user} />
    }else{
      return <PopUpLook isUserLoading={isLoading} user={user} />
    }
    
  }

  return null
}

export {SettingsEditModalFormWrapper}
