import React from 'react'
import { SettingsListWrapper } from './settings-list/SettingsListWrapper'

export const Ayarlar = () => {
  return (
    <div>
      <SettingsListWrapper />
    </div>
  )
}
