import React from 'react'
import { ProjectListSearchComponent } from './ProjectListSearchComponent'
import { ProjectListGrouping } from './ProjectListGrouping'
import { ProjectListToolbar } from './ProjectListToolbar'
import { useListView } from '../core/ListViewProvider'

export const ProjectListHeader = () => {
    const { selected } = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            <ProjectListSearchComponent />
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {selected.length > 0 ? <ProjectListGrouping /> : <ProjectListToolbar />}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}
