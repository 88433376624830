import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Blog, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_BLOGS_URL = `${API_URL}contentTransactions/blog/List`
const GET_BLOG_DETAIL_URL = `${API_URL}contentTransactions/blog/Detail`
const ADD_BLOG_URL = `${API_URL}contentTransactions/blog/Add`
const EDİT_BLOG_URL = `${API_URL}contentTransactions/blog/Edit`
const DELETE_BLOG_URL = `${API_URL}contentTransactions/blog/Delete`


const getBlog = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_BLOGS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getBlogDetails = (id: ID): Promise<Blog | undefined> => {
  const all = axios
    .post(`${GET_BLOG_DETAIL_URL}/${id}`)
    .then((response: Response<Blog>) => response.data)
    return all;
}

const addBlog = (user: Blog): Promise<Blog | undefined> => {
  return axios
    .post(ADD_BLOG_URL, user)
    .then((response: Response<Blog>) => response.data)
}

const editBlog = (user: Blog): Promise<Blog | undefined> => {
  return axios
    .post(`${EDİT_BLOG_URL}/${user.id}`, user)
    .then((response: Response<Blog>) => response.data)
}

const deleteBlog= (userId: ID): Promise<void> => {
  return axios.delete(`${DELETE_BLOG_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${DELETE_BLOG_URL}/${id}`))
  return axios.all(requests).then(() => {})
}



export {deleteBlog, getBlog, deleteSelectedUsers, getBlogDetails, addBlog, editBlog}
