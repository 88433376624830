import React from 'react'
import { ReferenceListWrapper } from './reference-list/ReferenceListWrapper'
import PageTitle from './../../../../../../../site/components/Common/PageTitle';

export const Referans = () => {
  return (
    <div>
      <h1>Referans</h1>
      <ReferenceListWrapper />
    </div>
  )
}
