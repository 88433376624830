import {ID, Response} from '../../../../../../_metronic/helpers'
export type Setting = {
  id?: ID
  title?: string
  active?: boolean
  cr_date?: string
  text?: string
  image?: string
  video?: string
  dosya_video?: string
  logo?: string
  substructure ?: string
  category ?: string
  site ?: string
  content_image?:string
  problem_content?: string
  solution_content?: string
  result_content?: string
  list_text?:string
  summary_text?: string
  content?: string
  fullname?: string
  resim?: string
  header?: string
  tags?: string[]
  user?:string
  name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  component?:string
  initials?: {
    label: string
    state: string
  }
}

export type UsersQueryResponse = Response<Array<Setting>>

export const initialUser: Setting = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
  active : false,
  text: ""
}
