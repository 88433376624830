/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes, Outlet} from 'react-router-dom'
import { TokenTransaction } from './components/TokenTransactions'

const ErrorsLayout = () => {
  return <Outlet />
}

const GetToken = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='token' element={<TokenTransaction />} />
      <Route index element={<TokenTransaction />} />
    </Route>
  </Routes>
)

export {GetToken}
