import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import InputMask from 'react-input-mask';



export const CreditCard = () => {
    const values = {
        cvc: "",
        expiry: "",
        focus: "",
        name: "",
        number: "",
    };

    const [state, setState] = useState(values)
    const formik = useFormik({
        initialValues: state,
        enableReinitialize: true,
        validationSchema: Yup.object({
            number: Yup.string()
                .matches(/^\d{4}\s\d{4}\s\d{4}\s\d{4}$/, 'Kart numarası 16 haneli olmalıdır')
                .required('Kart Numarası zorunludur'),
            expiry: Yup.string()
                .matches(
                    /(0[1-9]|1[0-2])\/([0-99]{2})/,
                    'Geçerli bir son kullanma tarihi değil.'
                )
                .required('Son Kullanma Tarihi zorunludur'),
            cvc: Yup.string()
                .min(3, 'Minimum 3 karakter')
                .max(3, 'Maksimum 3 karakter')
                .required('CVC zorunludur'),
            name: Yup.string()
                .required('Ad ve Soyad zorunludur')
                .min(3, 'Minimum 3 karakter'),
        }),
        onSubmit: values => {

            alert(JSON.stringify(values, null, 2));
        },
    });

    const handleInputFocus = (e: any) => {
        setState(prevState => ({ ...prevState, focus: e.target.name }));
    }
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        formik.setTouched({ ...formik.touched, name: true });
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const onSubmit = () => {

    }
    useEffect(()=> {
        
    },[formik.values])
    return (
        <div className="ozet-item ">
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className="row justify-content-center mt-4">
                    <div className='card-header cursor-pointer'>
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>Kredi veya Banka Kartınızı Giriniz</h3>
                        </div>
                    </div>
                    <div className='fv-row mb-7 col-lg-6'>
                        <div className='form-group-item mt-5'>
                            
                        </div>
                        <div className="form-group-item">
                            <label className='fw-bold fs-6 mb-2 mt-4' style={{ fontSize: "1.5rem" }}>Kart Numarası</label>
                            <InputMask
                                mask="9999 9999 9999 9999"
                                className={clsx(
                                    "form-control bg-transparent",
                                    {
                                        'is-invalid': formik.touched.number && formik.errors.number,
                                    },
                                    {
                                        'is-valid': formik.touched.number && !formik.errors.number
                                    }
                                )}
                                type="tel"

                                placeholder="Card Number"
                                {...formik.getFieldProps("number")}
                                onBlur={() => { formik.setTouched({ ...formik.touched, number: true }); }}
                                onFocus={(e: any) => { handleInputFocus(e) }}
                                onChange={(e: any) => { handleInputChange(e) }}
                            />
                            {formik.touched.number && formik.errors.number && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className="text-danger">{formik.errors.number}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="form-group-item">
                            <label className='fw-bold fs-6 mb-2 mt-4' style={{ fontSize: "1.5rem" }}>Son Kullanma Tarihi</label>
                            <InputMask mask="99/99"
                                className={clsx(
                                    "form-control bg-transparent",
                                    {
                                        'is-invalid': formik.touched.expiry && formik.errors.expiry,
                                    },
                                    {
                                        'is-valid': formik.touched.expiry && !formik.errors.expiry
                                    }
                                )}
                                type="tel"
                                placeholder="Card Number"
                                {...formik.getFieldProps("expiry")}
                                onFocus={(e: any) => { handleInputFocus(e) }}
                                onChange={(e: any) => { handleInputChange(e) }}

                            />
                            {formik.touched.expiry && formik.errors.expiry && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className="text-danger">{formik.errors.expiry}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="form-group-item">
                            <label className='fw-bold fs-6 mb-2 mt-4' style={{ fontSize: "1.5rem" }}>Ad - Soyad</label>
                            <input
                                className={clsx(
                                    "form-control bg-transparent",
                                    {
                                        'is-invalid': formik.touched.name && formik.errors.name,
                                    },
                                    {
                                        'is-valid': formik.touched.name && !formik.errors.name
                                    }
                                )}
                                type="tel"
                                {...formik.getFieldProps("name")}
                                placeholder="Name"
                                onChange={(e: any) => { handleInputChange(e) }}
                                onFocus={(e: any) => { handleInputFocus(e) }}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className="text-danger">{formik.errors.name}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="form-group-item">
                            <label className='fw-bold fs-6 mb-2 mt-4' style={{ fontSize: "1.5rem" }}>CVC</label>
                            <input
                                className={clsx(
                                    "form-control bg-transparent",
                                    {
                                        'is-invalid': formik.touched.cvc && formik.errors.cvc,
                                    },
                                    {
                                        'is-valid': formik.touched.cvc && !formik.errors.cvc
                                    }
                                )}
                                type="tel"
                                onFocus={(e: any) => { handleInputFocus(e) }}
                                {...formik.getFieldProps("cvc")}
                                placeholder="CVC"
                            />
                            {formik.touched.cvc && formik.errors.cvc && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className="text-danger">{formik.errors.cvc}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <button onClick={() => (onSubmit())} disabled={formik.isSubmitting || !formik.isValid} type="button" className="btn btn-primary mt-3 w-100">
                            Ödeme Yap
                        </button>
                    </div>
                </div>
            </div>
        </div>


    );
}
