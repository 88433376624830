import React from 'react'
import { BlogListWrapper } from './blog-list/BlogListWrapper'


export const Blog = () => {
  return (
    <div>
      {/* // resim başlık etiketler oluşturulma_tarihi oluşturan  */}
      <h1>Blog</h1>
      <BlogListWrapper/>
    </div>
  )
}
