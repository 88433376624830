import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import { Blog } from '../blog-list/core/_models'

type Props = {
  column: ColumnInstance<Blog>
}

const BlogHeaderColumn: FC<Props> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

export {BlogHeaderColumn}
