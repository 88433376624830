/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { getHomePageInfo } from '../../../../app/modules/apps/user-management/customers-list/core/_requests'
import { Link } from 'react-router-dom'

type Props = {
  className: string
}

const ListsWidget1: React.FC<Props> = ({ className }) => {
  const [sales, setSales] = useState<any>()
  const getOfferDetails = async () => {
    await getHomePageInfo().then(response => {
      setSales(response.data.aktif)
    })
  }
  useEffect(() => {
    getOfferDetails()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Aktif Hizmetler</h3>
      </div>
      <div className='card-body pt-2' >

        {sales?.map((s: any, index: number) => {
          return (
            <div className='d-flex align-items-center mb-7' key={index}>
              <div className='symbol symbol-50px me-5'>
                <img src={`${process.env.REACT_APP_MEDIA_API_URL}users/${s?.icon}`} className='' alt='' />
              </div>
              <div className='flex-grow-1'>
                <Link
                  to={`/apps/user-management/offers/${s.id}/details`}
                >
                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                    {s.type_name}
                  </span>
                </Link>
                <span className='text-muted d-block fw-semibold'>{s.name} {s.surname}</span>
              </div>
            </div>
          )
        })}
      </div>

    </div>
  )
}

export { ListsWidget1 }
