import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import { Setting } from '../settings-list/core/_models'


type Props = {
  column: ColumnInstance<Setting>
}

const SettingsHeaderColumn: FC<Props> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

export {SettingsHeaderColumn}
